import React, { useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Header } from 'semantic-ui-react';
import { FORM_ERROR } from 'final-form';
import { combineValidators, isRequired } from 'revalidate';

import { RootStoreContext } from '../../app/stores/rootStore';
import { IForgotPasswordModel } from './models';
import TextInput from '../../app/common/form/TextInput';
import ErrorMessage from '../../app/common/form/ErrorMessage';
import LoginContainer from './LoginContainer';
import { Link } from 'react-router-dom';
import Routes from '../../app/layout/Routes';

const validate = combineValidators({
	email: isRequired('Email'),
});

const ForgotPasswordForm = () => {
	const rootStore = useContext(RootStoreContext);
	const { forgotPassword } = rootStore.userStore;
	return (
		<LoginContainer innerForm={
			<FinalForm
				onSubmit={(values: IForgotPasswordModel) =>
					forgotPassword(values).catch(error => ({
						[FORM_ERROR]: error
					}))
				}
				validate={validate}
				render={({
					handleSubmit,
					submitting,
					submitError,
					invalid,
					pristine,
					dirtySinceLastSubmit
				}) => (
						<Form onSubmit={handleSubmit} error>
							<Header
								as='h2'
								content='Forgot password'
								color='teal'
								textAlign='center' />
							<Field name='email' component={TextInput} placeholder='Email' />
							{submitError && !dirtySinceLastSubmit && (<ErrorMessage error={submitError} />)}
							<Link className='login-link' to={Routes.Login()}>Back to login</Link>
							<Button className="login-button"
								disabled={(invalid && !dirtySinceLastSubmit) || pristine}
								loading={submitting}
								color='teal'
								content='Recover'
								fluid />
						</Form>
					)}
			/>}
		/>
	);
};

export default ForgotPasswordForm;
