export default class SessionStore<T> {

    private value: T;

    constructor(private storageKey: string) {
        let savedSettingsJson = sessionStorage && sessionStorage.getItem(storageKey);
        this.value = savedSettingsJson && JSON.parse(savedSettingsJson);
    }

    getValue = (): T => this.value;

    getPropertyValue = <TValue>(propertyValueSelector: (x: T) => TValue, defaultValue: TValue): TValue => {
        let propertyValue = this.value && propertyValueSelector(this.value);

        // 0, '', false - is valid values
        return propertyValue === undefined || propertyValue === null
            ? defaultValue
            : propertyValue;
    };

    saveValue = (value: T): void => {
        sessionStorage && sessionStorage.setItem(this.storageKey, JSON.stringify(value));
    };
}