import UserStore from '../../features/user/userStore'
import { createContext } from 'react'
import CommonStore from './commonStore'
import ConfirmModalStore from './confirmModalStore'
import ProvidersStore from '../../features/admin/providers/providersStore'
import MagazinesStore from '../../features/admin/magazines/magazinesStore'
import JobsStore from './jobsStore'
import PdfFilesStore from '../../features/pdf-files/pdfFilesStore'
import LogsStore from '../../features/admin/logs/logsStore'
import LandingStore from '../../features/landing/landingStore'
import WidgetsStore from '../../features/widgets/widgetsStore'

export class RootStore {

    userStore = new UserStore(this)
    commonStore = new CommonStore(this)
    confirmModalStore = new ConfirmModalStore(this)
    providersStore = new ProvidersStore(this)
    magazinesStore = new MagazinesStore(this)
    pdfFilesStore = new PdfFilesStore(this)
    landingStore = new LandingStore(this)
    widgetsStore = new WidgetsStore(this)
    jobsStore = new JobsStore(this)
    logsStore = new LogsStore(this)
}

export const RootStoreContext = createContext(new RootStore())