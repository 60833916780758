import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PageResponse } from '../models/PageResponse';

export interface PagedFilters {
    pageIndex: number;
}

export default class PagedStoreBase<TItem, TFilters> implements IPagedStore {

    // Paging
    pageSize: number = 20;
    firstPageIndex: number = 1;

    protected pageIndexSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(this.firstPageIndex);
    pageIndex$: Observable<number> = this.pageIndexSubject$.asObservable();

    setPageIndex = (pageIndex: number) => {
        this.pageIndexSubject$.next(pageIndex);
    }

    resetPage = () => {
        this.setPageIndex(this.firstPageIndex);
    }

    // Sort
    private readonly sortFieldSubject$: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
    sortField$: Observable<string | null> = this.sortFieldSubject$.asObservable();

    setSortField = (sortField: string | null, resetPage: boolean = true) => {
        resetPage && this.resetPage();
        this.sortFieldSubject$.next(sortField);
    }

    private readonly sortAscSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    sortAsc$: Observable<boolean> = this.sortAscSubject$.asObservable();

    setSortAsc = (sortAsc: boolean, resetPage: boolean = true) => {
        resetPage && this.resetPage();
        this.sortAscSubject$.next(sortAsc);
    }

    handleSortClick = (sortField: string) => {
        if (this.sortFieldSubject$.value === sortField) {
            this.setSortAsc(!this.sortAscSubject$.value);
        }
        else {
            this.setSortField(sortField);
            this.setSortAsc(true);
        }
    }

    // Filters
    private readonly filtersSubject$: BehaviorSubject<Filter[]> = new BehaviorSubject<Filter[]>([]);
    filters$: Observable<Filter[]> = this.filtersSubject$.asObservable();

    getFilters = () => this.filtersSubject$.value;
    setFilters = (filters: Filter[], resetPage: boolean = true) => {
        resetPage && this.resetPage();
        let notEmptyFilters = filters.filter(filter => filter.value !== '');
        this.filtersSubject$.next(notEmptyFilters);
    }

    setFilter = (field: string, value: string, resetPage: boolean = true) => {
        resetPage && this.resetPage();
        let newFilters = this.filtersSubject$.value.filter(x => x.field !== field);
        if (value !== '') {
            newFilters.push({
                field: field,
                //condition: FilterCondition.FieldContainsValue
                value: value,
            });
        }

        this.filtersSubject$.next(newFilters);
    }

    clearFilters = (resetPage: boolean = true) => {
        resetPage && this.resetPage();

        this.filtersSubject$.next([]);
    }

    getFilter$(field: string): Observable<string | null> {

        return this.filters$
            .pipe(map(filters => {
                let filter = filters.find(x => x.field === field);

                return (filter && filter.value) || null;
            }));
    }

    protected forceReloadSubject$: Subject<boolean> = new Subject<boolean>();
    forceReload$: Observable<boolean> = this.forceReloadSubject$.asObservable();

    forceReload = () => {
        this.forceReloadSubject$.next(true);
    }

    // Main
    page$!: Observable<PageResponse<TItem>>;
    items$!: Observable<TItem[]>;
    totalCount$!: Observable<number>;
}

export interface IPagedStore {
    pageSize: number;
    firstPageIndex: number;
    pageIndex$: Observable<number>;
    totalCount$: Observable<number>;
    setPageIndex: (pageIndex: number) => void;
}

export interface Filter {
    field: string;
    // condition: FilterCondition;
    value: string;
    //values?: string[];
}

