import React, { useContext, useEffect } from 'react'
import { Container } from 'semantic-ui-react'
import { observer } from 'mobx-react-lite'
import { Route, withRouter, RouteComponentProps, Switch } from 'react-router-dom'
import { RootStoreContext } from '../stores/rootStore'
import { ToastContainer } from 'react-toastify'

import NotFound from './NotFound'
import NavBar from '../../features/nav/NavBar'
import LoginForm from '../../features/user/LoginForm'
import LoadingComponent from './LoadingComponent'
import ForgotPasswordForm from '../../features/user/ForgotPasswordForm'
import ResetPasswordForm from '../../features/user/ResetPasswordForm'
import ProviderEditForm from '../../features/admin/providers/ProviderEditForm'
import ConfirmModal from '../common/modals/ConfirmModal'
import UsersView from '../../features/admin/users/UsersView'
import UserEditForm from '../../features/admin/users/UserEditForm'
import Forbidden from './Forbidden'
import PrivateRoute from './PrivateRoute'
import UserProfile from '../../features/user-profile/UserProfile'
import ConfirmEmail from '../../features/user/ConfirmEmail'
import Registered from '../../features/user/Registered'
import { PrepareForDeployBox } from '../../features/Common'
import Routes from './Routes'
import BitrateMappingView from '../../features/admin/magazines/MagazinesView'
import ProvidersView from '../../features/admin/providers/ProvidersView'
import PdfFilesView from '../../features/pdf-files/PdfFilesView'
import LogsView from '../../features/admin/logs/LogsView'
import LandingView from '../../features/landing/LandingView'
import WidgetsView from '../../features/widgets/WidgetsView'

const App: React.FC<RouteComponentProps> = () => {
    const rootStore = useContext(RootStoreContext)
    const { setAppLoaded, token, appLoaded } = rootStore.commonStore
    const { getUser } = rootStore.userStore

    useEffect(() => {
        token ? getUser().finally(() => setAppLoaded()) : setAppLoaded()
    }, [getUser, setAppLoaded, token])

    if (!appLoaded) {
        return <LoadingComponent content='Loading app...' />
    }

    return (
        <>
            <ConfirmModal />
            <ToastContainer position='top-right' />
            <NavBar />
            <Container fluid className="app-container">
                <PrepareForDeployBox />
                <Switch>
                    <Route exact path='/' component={LandingView} />

                    <PrivateRoute exact path={Routes.PdfFiles()} isAdminCan={true} component={PdfFilesView} />
                    <PrivateRoute exact path={Routes.PdfFile(':id')} isAdminCan={true} component={PdfFilesView} />

                    <PrivateRoute exact path={Routes.Widgets()} isAdminCan={true} component={WidgetsView} />

                    <PrivateRoute exact path={Routes.Profile()} isRegisteredCan={true} isUserCan={true} isAdminCan={true} component={UserProfile} />
                    <Route path={Routes.Login()} component={LoginForm} />
                    <Route path='/registered' component={Registered} />
                    <Route path='/email-confirm' component={ConfirmEmail} />
                    {/* 
                    <Route path='/register' component={RegisterForm} />
                    */}
                    <Route path='/recover-password' component={ForgotPasswordForm} />
                    <Route path='/reset-password' component={ResetPasswordForm} />

                    <PrivateRoute path='/admin' isAdminCan={true}>
                        <Switch>
                            <Route path={Routes.Providers()} component={ProvidersView} exact />
                            <Route path={Routes.ProviderEdit(':id')} component={ProviderEditForm} />
                            <Route path={Routes.Users()} component={UsersView} exact />
                            <Route path={Routes.UserEdit(':id')} component={UserEditForm} />
                            <Route path={Routes.Magazines()} component={BitrateMappingView} exact />
                            <Route path={Routes.Logs()} component={LogsView} exact />
                            <Route component={NotFound} />
                        </Switch>
                    </PrivateRoute>

                    <Route path={Routes.Forbidden()} component={Forbidden} />
                    <Route component={NotFound} />
                </Switch>
            </Container>
        </>
    )
}

export default withRouter(observer(App))
