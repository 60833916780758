import React, { useContext } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { RootStoreContext } from '../../stores/rootStore';
import { observer } from 'mobx-react-lite';

const ConfirmModal: React.FC = () => {

    const rootStore = useContext(RootStoreContext);
	const { modalProps, closeModal } = rootStore.confirmModalStore;

    const onYesClickHandler = () => {
        modalProps.onYes && modalProps.onYes();
        closeModal();
    }

    const onCancelClickHandler = () => {
        modalProps.onCancel && modalProps.onCancel();
        closeModal();
    }

    return (
        <Modal size={"mini"} open={modalProps.open} onClose={modalProps.onClose}>
            <Modal.Header>{modalProps.header}</Modal.Header>
            <Modal.Content>
                <p>{modalProps.text}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button primary onClick={onYesClickHandler}>Yes</Button>
                <Button
                    labelPosition='right'
                    content='Cancel'
                    onClick={onCancelClickHandler} />
            </Modal.Actions>
        </Modal>
    )
}

export default observer(ConfirmModal)