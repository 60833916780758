import { PageRequest, IPagedParams, ISortedParams, IFilteredParams } from "../../../app/models/request-response-base";
import { ProviderMode } from "../../../app/models/models";

export interface ProviderRow {
    id: string;
    name: string;
    smoothsEncodingProfile: number;
    mode: ProviderMode;
}

export interface Provider {
    id: string;
    name: string; // required 
    tsPath: string;  // required
    smoothPath: string;// required
    ftpUrl: string; //required
    ftpUser: string;  // required
    ftpPass: string;   // required
    ftpPort: number;   // required
    ftpProtocol: string;  // required
    ftpPassive: boolean;
    mode: ProviderMode;
}

export interface ProvidersParams extends IPagedParams, ISortedParams, IFilteredParams {
    searchFilter: string | null
}

export interface ProviderRowsRequest extends PageRequest {
    searchFilter: string | null
}

export enum ProviderRowField {
    Title = 'Title',
    AssetExtId = 'AssetExtId',
    AssetIntId = 'AssetIntId',
    AssetType = 'AssetType',
    ModifiedDate = 'ModifiedDate',
    Priority = 'Priority',
    Status = 'Status',
}