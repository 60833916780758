import React, { useContext, useEffect, useState } from 'react'
import { Segment, Button, Header, Icon, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { RootStoreContext } from '../../app/stores/rootStore'
import { observer } from 'mobx-react-lite'
import Routes from '../../app/layout/Routes'

const ConfirmEmail: React.FC = (props: any) => {

    const rootStore = useContext(RootStoreContext)
    const { confirmUser, confirmingUser } = rootStore.userStore
    const [error, setError] = useState(null)

    useEffect(() => {
        let isSubscribed = true

        let params: any = new URL(window.location.href).searchParams
        let code = params.get('code')
        let userId = params.get('userId')

        confirmUser({ code, userId }).catch((error) => {
            isSubscribed && setError(error.data.errors.Confirm)
        })

        return () => {
            isSubscribed = false
        }
    }, [confirmUser, props.location.search])

    return (
        <>
            {
                confirmingUser
                    ? <Loader active={confirmingUser} content={'Confirming user...'} />
                    : error == null
                        ?
                        <Segment placeholder>
                            <Header icon>
                                <Icon name='thumbs up' />
                            You have successfully confirm your email.
                        </Header>
                            <Segment.Inline>
                                <Button as={Link} to={Routes.Profile()} primary>
                                    Go to profile
                            </Button>
                            </Segment.Inline>
                        </Segment>
                        :
                        <Segment placeholder>
                            <Header icon>
                                <Icon name='close' />
                            Can't confirm you account: {error}. Please contact administrator.
                        </Header>
                            <Segment.Inline>
                                <Button as={Link} to={Routes.Login()} primary>
                                    Go to Login
                            </Button>
                            </Segment.Inline>
                        </Segment>

            }
        </>
    )
}
export default observer(ConfirmEmail)


