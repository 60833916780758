import { MagazineType } from "../../app/models/models"
import { IPagedParams, ISortedParams, IFilteredParams, PageRequest } from "../../app/models/request-response-base"

export interface FeatureToggleDto {
    showUiLinkToOldApp: boolean
}

export interface LandingPdfFileRow {
    id: string

    year: number
    week: number
    isAttachment: boolean | null
    isChronicles: boolean | null
    magazineName: string
    magazineSlug: string
}

export interface LandingMagazineRow {
    id: string
    slug: string
    name: string
}

export interface PdfFilesParams extends IPagedParams, ISortedParams, IFilteredParams {
    magazineSlugFilter: string | null
    yearFilter: number | null
    weekFilter: number | null
    typeFilter: MagazineType | null
}

export interface LandingPdfFileRowsRequest extends PageRequest {
    magazineSlugFilter: string | null
    yearFilter: number | null
    weekFilter: number | null
    typeFilter: MagazineType | null
}

export enum LandingPdfFileRowField {
    Title = 'Title',
    AssetExtId = 'AssetExtId',
    AssetIntId = 'AssetIntId',
    AssetType = 'AssetType',
    ModifiedDate = 'ModifiedDate',
    Priority = 'Priority',
    Status = 'Status',
}