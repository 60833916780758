import React from 'react'
import { Select, DropdownProps } from 'semantic-ui-react'
import { MagazineType } from '../../app/models/models';

export const valueToStr = (value: MagazineType | null): string => value === null ? 'null' : value.toString()
export const strToValue = (str: string): MagazineType | null => str === 'null' ? null : Number(str) as MagazineType

export const options = [
    { value: valueToStr(null), text: 'Alla utgåvor' }, // text: 'All types' },
    { value: valueToStr(MagazineType.Normal), text: 'Normal' },
    { value: valueToStr(MagazineType.Attachment), text: 'Bilaga' },
    { value: valueToStr(MagazineType.Chronicles), text: 'Årskröniker' },
]

export default (props: {
    filter: MagazineType | null,
    setFilter: (filter: MagazineType | null) => void,
    placeholder?: string,
}) => {
    let { filter, setFilter, placeholder } = props;

    return (
        <Select
            options={options}
            placeholder={placeholder}
            value={valueToStr(filter)}
            onChange={(event: any, data: DropdownProps) => setFilter(strToValue(data.value as string))} />
    )
}
