import React, { useContext, useMemo } from 'react';
import { RootStoreContext, RootStore } from '../../app/stores/rootStore';
import { Loader } from 'semantic-ui-react';
import agent from '../../app/api/agent';
import { useObservable, FlowButton, refreshWithTimer } from '../Common';
import { of } from 'rxjs';
import { PdfFile, PdfFileStatus, PdfFileStatuses } from './models';

const updateStatus = (x: RootStore) => x.pdfFilesStore.updateStatus

const MoveButton = FlowButton({
    title: 'Move',
    statusTo: PdfFileStatus.ToMove,
    availableIn: PdfFileStatuses.MoveAvailable,
    updateStatusGetter: updateStatus
})
const MoveRetryButton = FlowButton({
    title: 'Re-move',
    statusTo: PdfFileStatus.ToMove,
    availableIn: PdfFileStatuses.MoveRetryAvailable,
    primaryIn: [PdfFileStatus.MoveError],
    updateStatusGetter: updateStatus
})

const DownloadButton = FlowButton({
    title: 'Download',
    statusTo: PdfFileStatus.ToDownload,
    availableIn: PdfFileStatuses.DownloadAvailable,
    updateStatusGetter: updateStatus
})
const DownloadRetryButton = FlowButton({
    title: 'Re-download',
    statusTo: PdfFileStatus.ToDownload,
    availableIn: PdfFileStatuses.DownloadRetryAvailable,
    primaryIn: [PdfFileStatus.DownloadError],
    updateStatusGetter: updateStatus
})

const ProcessButton = FlowButton({
    title: 'Process',
    statusTo: PdfFileStatus.ToProcess,
    availableIn: PdfFileStatuses.ProcessAvailable,
    updateStatusGetter: updateStatus
})
const ProcessRetryButton = FlowButton({
    title: 'Re-process',
    statusTo: PdfFileStatus.ToProcess,
    availableIn: PdfFileStatuses.ProcessRetryAvailable,
    primaryIn: [PdfFileStatus.ProcessError],
    updateStatusGetter: updateStatus
})

export default (props: {
    pdfFile: PdfFile,
}) => {

    let { pdfFile } = props;
    let { status } = pdfFile;

    let rootStore = useContext(RootStoreContext);
    let { isUpdating$ } = rootStore.pdfFilesStore;
    let updating = useObservable(isUpdating$, false)

    let buttonProps = {
        id: pdfFile.id,
        status: pdfFile.status,
        updating: updating
    };

    let lastLogs$ = useMemo(() => {
        return PdfFileStatus[status].endsWith('ing')
            ? refreshWithTimer(() => agent.Logs.realtimeLastN(pdfFile.id, 1), 1)
            : of(null);
    }, [status, pdfFile.id])
    let lastLogs = useObservable(lastLogs$, null);

    return (
        <>
            {
                PdfFileStatus[status].startsWith('To') &&
                <>
                    <Loader active inline className='mr-20' />
                    Waiting to be processed
                </>
            }
            {
                PdfFileStatus[status].endsWith('ing') &&
                <>
                    <Loader active inline className='mr-20' />
                    {lastLogs || PdfFileStatus[status]}
                </>
            }
            <MoveButton {...buttonProps} />
            <DownloadButton {...buttonProps} />
            <ProcessButton {...buttonProps} />

            <ProcessRetryButton {...buttonProps} />
            <DownloadRetryButton {...buttonProps} />
            <MoveRetryButton {...buttonProps} />
        </>
    );
}