import axios, { AxiosResponse } from 'axios'
import { history } from '../..'
import { toast } from 'react-toastify'

import { UserRow, IRegisterUserModel, IForgotPasswordModel, IResetPasswordModel, IUsersPageRequestModel, IUserUpdateDtoModel, IConfirmUserModel } from '../../features/user/models'
import { ProviderRow, Provider, ProviderRowsRequest } from '../../features/admin/providers/models'
import { Magazine, MagazinePageRequest, MagazineUpdateDto } from '../../features/admin/magazines/models'
import { Observable, from, Subject } from 'rxjs'
import { map, finalize, throttleTime } from 'rxjs/operators'
import { PrepareForDeployStatus } from '../models/jobs'
import { UpdatePriorityRequest, UpdateStatusRequest, StatusCountRequest, StatusCount } from '../models/models'
import { PageResponse } from '../models/PageResponse'
import { PdfFile, PdfFileRow, PdfFileStatus, PdfFileRowsRequest } from '../../features/pdf-files/models'
import { LandingPdfFileRowsRequest, LandingPdfFileRow, LandingMagazineRow, FeatureToggleDto } from '../../features/landing/models'
import { PdfFileType } from '../../features/widgets/models'

axios.defaults.baseURL = process.env.REACT_APP_API_URL

const cancelToken = axios.CancelToken

axios.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('jwt')
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
}, error => {
    return Promise.reject(error)
})

let requestError$ = new Subject()
requestError$
    .pipe(throttleTime(5000))
    .subscribe(() => toast.error('Request error'))

axios.interceptors.response.use(x => x, error => {

    if (error instanceof axios.Cancel) {
        return
    }

    if (!error.response) {
        requestError$.next()

        return
    }

    let { status, data } = error.response

    if (status === 401) {
        toast.error('401 Unauthorized')
    }
    else if (status === 404) {
        history.push('/notfound')
    }
    else if (status === 403) {
        toast.error('403 Forbidden')
    }
    else if (status === 400 && data.title) {
        toast.error(data.title)
    }
    else if (status === 500) {
        toast.error('Server error')
    }

    throw error.response
})

const responseBody = (response: AxiosResponse) => (response && response.data) || null

// Just for testing purpose
// const sleep = (ms: number) => (response: AxiosResponse) =>
//     new Promise<AxiosResponse>(resolve => setTimeout(() => resolve(response), ms))

let get = <T>(url: string): Observable<T> => {
    let cancelTokenSource = cancelToken.source()

    return from(axios.get(url, { cancelToken: cancelTokenSource.token }))
        .pipe(
            map(responseBody),
            finalize(cancelTokenSource.cancel),
        )
}
let post = <T>(url: string, body: any): Observable<T> => {
    let cancelTokenSource = cancelToken.source()

    return from(axios.post(url, body, { cancelToken: cancelTokenSource.token }))
        .pipe(
            map(responseBody),
            finalize(cancelTokenSource.cancel),
        )
}

const Links = {
    magazinePdf: (id: string) =>
        `${process.env.REACT_APP_ROOT_URL}/magazines/${id}`,
    magazineRead: (id: string, magazineSlug: string, year: number, week: number) =>
        `${process.env.REACT_APP_ROOT_URL}/magazines/read/${magazineSlug}/${year}/${week}/${id}`,
    magazineThumbnail: (id: string, width: number, height: number) =>
        `${process.env.REACT_APP_ROOT_URL}/magazines/thumbnail/w${width}h${height}/${id}`,
    magazineWidgetAbsoluteUrl: (slug: string, pdfFileType: PdfFileType | null) =>
        `${process.env.REACT_APP_ROOT_URL || window.location.origin}/magazines/widget/${slug}${pdfFileType === null ? '' : '/' + PdfFileType[pdfFileType].toLowerCase()}`,
}

const Public = {
    magazines: (request: MagazinePageRequest) => post<LandingMagazineRow[]>(`/landing/magazines`, request),
    pdfFiles: (request: LandingPdfFileRowsRequest) => post<PageResponse<LandingPdfFileRow>>(`/landing/pdf-files`, request),
    featureToggle: () => post<FeatureToggleDto>(`/landing/feature-toggle`, {}),
}

const Magazines = {
    list: (request: MagazinePageRequest) => post<PageResponse<Magazine>>(`/magazines/list`, request),
    add: (bitrateMapping: MagazineUpdateDto) => post('/magazines/add', bitrateMapping),
    update: (bitrateMapping: MagazineUpdateDto) => post('/magazines/update', bitrateMapping),
    remove: (bitrateMapping: MagazineUpdateDto) => post('/magazines/remove', bitrateMapping),
}

export const PdfFiles = {
    get: (id: string) => get<PdfFile>(`/pdf-files/pdf-file--${id}`),
    list: (request: PdfFileRowsRequest) => post<PageResponse<PdfFileRow>>(`/pdf-files/pdf-file-list`, request),
    statusCount: (request: StatusCountRequest) => post<StatusCount<PdfFileStatus>[]>(`/pdf-files/pdf-file-status-count`, request),
    updateStatus: (request: UpdateStatusRequest<PdfFileStatus>) => post<boolean>('/pdf-files/pdf-file-update-status', request),
    updatePriority: (request: UpdatePriorityRequest) => post<boolean>('/pdf-files/pdf-file-update-priority', request),
}

const User = {
    list: (request: IUsersPageRequestModel) => post<PageResponse<UserRow>>(`/users/user-list`, request),
    get: (id: string) => get<IUserUpdateDtoModel>(`/users/${id}`),
    update: (user: IUserUpdateDtoModel) => post('/users/update', user),
    confirm: (confirm: IConfirmUserModel) => post('/users/confirm', confirm),

    current: () => get<UserRow>('/users/current'),
    loginCookiesUrl: (): string => `${process.env.REACT_APP_API_URL}/users/login-cookies`,
    logoutCookiesUrl: (): string => `${process.env.REACT_APP_API_URL}/users/logout-cookies`,
    login: (user: IRegisterUserModel) => post<UserRow>('/users/login', user),
    register: (user: IRegisterUserModel) => post<UserRow>('/users/register', user),
    forgotPassword: (forgotPassword: IForgotPasswordModel) => post('/users/forgot-password', forgotPassword),
    resetPassword: (resetPassword: IResetPasswordModel) => post('/users/reset-password', resetPassword),
}

const Providers = {
    list: (request: ProviderRowsRequest) => post<PageResponse<ProviderRow>>(`/providers/provider-list`, request),
    add: () => post<string>('/providers/provider-add', {}),
    get: (id: string) => get<Provider>(`/providers/provider--${id}`),
    update: (provider: Provider) => post<Provider>('/providers/provider-update', provider),
    delete: (id: string) => post<any>(`/providers/provider-delete--${id}`, {})
}

const Logs = {
    list: () => get<{ keys: string[] }>(`/logs/realtime`),
    getLogs: (id: string) => get<string>(`/logs/realtime/${id}`),
    realtimeLastN: (id: string, count: number) => get<string>(`/logs/realtime/${id}/last/${count}`),
}

const Jobs = {
    statusPrepareForDeploy: () => get<PrepareForDeployStatus>('/jobs/prepare-for-deploy-status'),
    isPrepareForDeploy: () => get<boolean>('/jobs/is-prepare-for-deploy'),
    startPrepareForDeploy: () => get<boolean>('/jobs/start-prepare-for-deploy'),
    startPdfFilesSyncJob: () => post<boolean>('/jobs/start-pdf-files-sync', {}),
    startPdfFileJob: () => post<boolean>('/jobs/start-pdf-file', {}),
}

export default {
    Public,
    User,
    Providers,
    Magazines,
    PdfFiles,
    Logs,
    Jobs,
    Links,
}