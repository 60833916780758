import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from '../../../app/stores/rootStore';
import { Grid, Loader, Table, Menu, Pagination, Icon, Header } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Routes from '../../../app/layout/Routes';

const UsersView: React.FC = () => {

    const rootStore = useContext(RootStoreContext);
    const { loadPage, users, getTotalPages, setPage, page, loadingInitial, totalCount } = rootStore.userStore;

    const handleNextPage = (page: string | number | undefined) => {
        setPage(page);
    };

    useEffect(() => {
        loadPage();
    }, [loadPage]);

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h2'>Users</Header>
                    {
                        loadingInitial && totalCount === null
                            ?
                            <Loader active={loadingInitial} content={'Loading...'} />
                            :
                            <Table celled singleLine size='small'>

                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={7}>Email</Table.HeaderCell>
                                        <Table.HeaderCell width={7}>Display name</Table.HeaderCell>
                                        <Table.HeaderCell width={2}></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {users.map((item) =>
                                        <Table.Row key={item.id}>
                                            <Table.Cell>{item.email}</Table.Cell>
                                            <Table.Cell>{item.displayName}</Table.Cell>
                                            <Table.Cell>
                                                <Link to={Routes.UserEdit(item.id)} title={'Edit'}>
                                                    <Icon size="large" name='edit outline' />
                                                </Link>
                                            </Table.Cell>

                                        </Table.Row>
                                    )}
                                </Table.Body>
                                {
                                    getTotalPages <= 1
                                        ? <></>
                                        : <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='3'>
                                                    <Menu floated='right' pagination>
                                                        <Pagination
                                                            boundaryRange={0}
                                                            defaultActivePage={page}
                                                            ellipsisItem={null}
                                                            firstItem={null}
                                                            lastItem={null}
                                                            siblingRange={1}
                                                            size='mini'
                                                            onPageChange={(event, data) => handleNextPage(data.activePage)}
                                                            totalPages={getTotalPages} />
                                                    </Menu>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                }
                            </Table>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default observer(UsersView)
