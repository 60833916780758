import { PageRequest } from "../../app/models/request-response-base";

export class Roles {
    public static readonly ADMIN: string = "ADMINISTRATOR";
    public static readonly USER: string = "USER";
    public static readonly REGISTERED: string = "REGISTERED";
}

export interface UserRow {
    id: string;
    username: string;
    displayName: string;
    email: string;
    token: string;
}

export interface IRegisterUserModel {
    displayName?: string;
    email: string;
    password: string;
    confirmPassword: string;
}

export interface IUsersPageRequestModel extends PageRequest {
    search: string | null;
}

export interface IForgotPasswordModel {
    email: string;
}

export interface IResetPasswordModel {
    password: string;
    confirmPassword: string;
    userId: string;
    code: string;
}

export interface IUserUpdateDtoModel {
    id: string,
    roles: string[]
    isAdminRole?: boolean,
    isRegisteredRole?: boolean,
    isUserRole?: boolean,
}

export interface IConfirmUserModel {
    userId: string;
    code: string;
}