import agent from '../../app/api/agent'
import { RootStore } from '../../app/stores/rootStore'
import PagedStoreBase, { Filter } from '../../app/stores/pagedStoreBase'
import { combineLatest, Observable, BehaviorSubject } from 'rxjs'
import { distinctUntilChanged, startWith, map, tap, switchMap, shareReplay, debounceTime } from 'rxjs/operators'
import { PageResponse } from '../../app/models/PageResponse'
import { LandingPdfFileRow, LandingPdfFileRowField, PdfFilesParams, LandingPdfFileRowsRequest, LandingMagazineRow } from './models'
import { defaultIfNull } from '../../app/models/request-response-base'
import { MagazineType } from '../../app/models/models'
import Routes from '../../app/layout/Routes'
import { createBrowserHistory } from 'history';

export default class LandingStore extends PagedStoreBase<LandingPdfFileRow, PdfFilesParams> {
    storageKey: string = 'LandingStore_filters_v1'
    defaultMagazineSlugFilter: string | null = null
    defaultYearFilter: number | null = null
    defaultWeekFilter: number | null = null
    defaultTypeFilter: MagazineType | null = null
    defaultSortField: LandingPdfFileRowField = LandingPdfFileRowField.Title
    defaultSortAsc: boolean = true
    defaultFilters: Filter[] = []

    constructor(private rootStore: RootStore) {
        super()

        let params: any = new URL(window.location.href).searchParams
        let magazineSlug = params.get('magazine')
        let yearStr = params.get('year')
        let weekStr = params.get('week')
        let typeStr = params.get('type')

        if (magazineSlug) {
            this.manualSelectMagazineSlug(magazineSlug)
        }
        if (yearStr) {
            let year = Number(yearStr)
            this.setYearFilter(year)
        }
        if (weekStr) {
            let week = Number(weekStr)
            this.setWeekFilter(week)
        }
        if (typeStr) {
            let type = typeStr === 'bilaga'
                ? MagazineType.Attachment
                : typeStr === 'arskroniker'
                    ? MagazineType.Attachment
                    : MagazineType.Normal
            this.setTypeFilter(type)
        }
    }

    pageSize: number = 24;

    //private sessionStore: SessionStore<PdfFilesParams> = new SessionStore<PdfFilesParams>(this.storageKey)

    private isMagazinesLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    isMagazinesLoading$: Observable<boolean> = this.isMagazinesLoadingSubject$.asObservable()
    magazines$ = combineLatest(
        this.forceReload$.pipe(startWith(true)),
    )
        .pipe(
            tap(() => this.isMagazinesLoadingSubject$.next(true)),
            switchMap(() => agent.Public.magazines({
                skip: 0,
                take: 1000,
                sortField: '',
                sortAsc: true,
                filters: [],
            })),
            tap(() => this.isMagazinesLoadingSubject$.next(false)),
            shareReplay({ bufferSize: 1, refCount: true }),
        )

    private manualSelectedMagazineSlugSubject$: BehaviorSubject<string | null>
        = new BehaviorSubject<string | null>(this.defaultMagazineSlugFilter)
    manualSelectedMagazine$: Observable<string | null> = this.manualSelectedMagazineSlugSubject$.asObservable()

    manualSelectMagazineSlug = (row: string | null) => {
        this.manualSelectedMagazineSlugSubject$.next(row)
    }

    currentMagazine$: Observable<LandingMagazineRow | null> =
        combineLatest(
            this.manualSelectedMagazineSlugSubject$.pipe(distinctUntilChanged()),
            this.magazines$.pipe(distinctUntilChanged()),
        )
            .pipe(
                map(([selectedSlug, items]): LandingMagazineRow | null => {
                    let updatedSelectedRow = selectedSlug
                        ? items.find(x => x.slug === selectedSlug)
                        : null

                    let result = updatedSelectedRow || null

                    return result
                }),
                distinctUntilChanged((x, y) => (x && y && x.slug === y.slug) || (x === y)),
            )

    public magazineSlugFilter$: Observable<string | null> = this.currentMagazine$.pipe(map(x => x && x.slug))

    private yearFilterSubject$: BehaviorSubject<number | null>
        = new BehaviorSubject<number | null>(this.defaultYearFilter)
    yearFilter$: Observable<number | null> = this.yearFilterSubject$.asObservable()

    setYearFilter = (filter: number | null, resetPage: boolean = true) => {
        resetPage && this.resetPage()
        this.yearFilterSubject$.next(filter)
    }

    private weekFilterSubject$: BehaviorSubject<number | null>
        = new BehaviorSubject<number | null>(this.defaultWeekFilter)
    weekFilter$: Observable<number | null> = this.weekFilterSubject$.asObservable()

    setWeekFilter = (filter: number | null, resetPage: boolean = true) => {
        resetPage && this.resetPage()
        this.weekFilterSubject$.next(filter)
    }

    private typeFilterSubject$: BehaviorSubject<number | null>
        = new BehaviorSubject<number | null>(this.defaultTypeFilter)
    typeFilter$: Observable<number | null> = this.typeFilterSubject$.asObservable()

    setTypeFilter = (filter: number | null, resetPage: boolean = true) => {
        resetPage && this.resetPage()
        this.typeFilterSubject$.next(filter)
    }


    private isPageLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    isPageLoading$: Observable<boolean> = this.isPageLoadingSubject$.asObservable()

    params$ = combineLatest(
        this.pageIndex$.pipe(debounceTime(300), distinctUntilChanged()),
        this.magazineSlugFilter$.pipe(debounceTime(300), distinctUntilChanged()),
        this.yearFilter$.pipe(debounceTime(300), distinctUntilChanged()),
        this.weekFilter$.pipe(debounceTime(300), distinctUntilChanged()),
        this.typeFilter$.pipe(debounceTime(300), distinctUntilChanged()),
        this.filters$.pipe(debounceTime(300), distinctUntilChanged()),
        this.sortField$.pipe(debounceTime(300), distinctUntilChanged()),
        this.sortAsc$.pipe(debounceTime(300), distinctUntilChanged()),
    )

    setLoading = (loading: boolean) => tap(() => this.isPageLoadingSubject$.next(loading))

    page$ = combineLatest(
        this.params$,
        this.forceReload$.pipe(startWith(true)),
    )
        .pipe(
            map<any, PdfFilesParams>(([[pageIndex, magazineSlugFilter, yearFilter, weekFilter, typeFilter, filters, sortField, sortAsc]]): PdfFilesParams => ({
                pageIndex: pageIndex,
                magazineSlugFilter: magazineSlugFilter,
                yearFilter: yearFilter,
                weekFilter: weekFilter,
                typeFilter: typeFilter,
                filters: filters,
                sortField: sortField,
                sortAsc: sortAsc,
            })),
            debounceTime(0),
            tap(params => {

                let p: any = {}
                params.magazineSlugFilter && (p.magazine = params.magazineSlugFilter)
                params.yearFilter && (p.year = params.yearFilter)
                params.weekFilter && (p.week = params.weekFilter)
                if (params.typeFilter) {
                    p.type = params.typeFilter === MagazineType.Attachment
                        ? 'bilaga'
                        : params.typeFilter === MagazineType.Chronicles
                            ? 'arskroniker'
                            : 'normal'
                }
                let history = createBrowserHistory();
                let newUrl = Routes.Landing() + '?' + new URLSearchParams(p).toString()
                history.replace(newUrl)

                //this.sessionStore.saveValue(params)
            }),
            map((params: PdfFilesParams): LandingPdfFileRowsRequest => ({
                magazineSlugFilter: params.magazineSlugFilter,
                yearFilter: params.yearFilter,
                weekFilter: params.weekFilter,
                typeFilter: params.typeFilter,
                skip: this.pageSize * (params.pageIndex - 1),
                sortField: params.sortField,
                sortAsc: params.sortAsc,
                take: this.pageSize,
                filters: params.filters,
            })),
            tap(() => this.isPageLoadingSubject$.next(true)),
            //switchMap(query => refreshWithTimer(() => agent.Public.pdfFiles(query), 10)),
            switchMap(query => agent.Public.pdfFiles(query)),
            tap(() => this.isPageLoadingSubject$.next(false)),
            defaultIfNull(PageResponse.Empty<LandingPdfFileRow>()),
            shareReplay({ bufferSize: 1, refCount: true }),
        )

    items$ = this.page$
        .pipe(
            map(page => page.items),
        )

    totalCount$ = this.page$
        .pipe(
            map(page => page.totalCount)
        )
}