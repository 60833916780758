import React, { useContext } from 'react';
import { Menu, Container, Dropdown, Icon } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { NavLink, Link } from 'react-router-dom';
import { RootStoreContext } from '../../app/stores/rootStore';
import './nav-bar.scss'
import Routes from '../../app/layout/Routes';

const NavBar: React.FC = () => {
    let { userStore } = useContext(RootStoreContext)
    const { user, logout, isUser, isAdmin } = userStore;

    return (
        <Menu stackable style={{ marginTop: '0', borderRadius: '0' }}>
            <Container fluid>
                <Menu.Item header as={NavLink} exact to={Routes.Landing()}>
                    <img src='/env/logo.png' alt='logo' style={{
                        marginRight: 10,
                        width: 'auto',
                        height: 'auto',
                        maxWidth: '150px',
                        maxHeight: '40px',
                        minHeight: '25px',
                    }} />
                </Menu.Item>

                {/* 
                    !user &&
                    <Menu.Item position='right' name='Login' as={NavLink} to={Routes.Login()} />
                */}
                {
                    user && (isUser || isAdmin) &&
                    <>
                        <Menu.Item name='Import' as={NavLink} to={Routes.PdfFiles()} />
                        <Menu.Item name='Magazines' as={NavLink} to={Routes.Magazines()} />
                        <Menu.Item name='Widgets' as={NavLink} to={Routes.Widgets()} />
                    </>
                }
                {/*
                    user && isAdmin &&
                    <Menu.Item position='right'>
                        <Dropdown pointing='top left' text='Administration'>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    as={Link}
                                    to={Routes.Providers()}
                                    text='Providers' />
                                <Dropdown.Item
                                    as={Link}
                                    to={Routes.Users()}
                                    text='Users' />
                                <Dropdown.Item
                                    as={Link}
                                    to={Routes.Logs()}
                                    text='Realtime logs' />
                                <a className="item"
                                    href={process.env.REACT_APP_HANGFIRE_URL}
                                    target='_blank'
                                    rel='noopener noreferrer'>
                                    <span className="text">Hangfire</span>
                                </a>
                                <Dropdown.Item
                                    onClick={() => agent.Jobs.startPrepareForDeploy().toPromise()}
                                    text='Start prepare for deploy' />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                */}
                {
                    user &&
                    <Menu.Item position='right'>
                        <Icon size="large" name='user outline' />
                        <Dropdown pointing='top right' text={user.displayName}>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    as={Link}
                                    to={Routes.Profile()}
                                    text='My profile'
                                    icon='user' />
                                <Dropdown.Item onClick={logout} text='Logout' icon='power' />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Item>
                }
            </Container>
        </Menu>
    );
};

export default observer(NavBar);
