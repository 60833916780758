import { observable, action, runInAction } from 'mobx';
import { RootStore } from '../../../app/stores/rootStore';

import { Magazine, MagazinePageRequest, MagazineUpdateDto } from './models';
import agent from '../../../app/api/agent';
import PagingStoreBase from '../../../app/stores/pagingStoreBase';

export default class MagazinesStore extends PagingStoreBase<MagazinePageRequest>
{
    constructor(protected rootStore: RootStore) {
        super(rootStore);
    }

    @observable bitrateMapping: Magazine[] = [];
    @observable loadingInitial = false;
    @observable user: Magazine | null = null;
    @observable mappingForEdit: MagazineUpdateDto | null = null;

    @action loadPage = async () => {
        this.loadingInitial = true;
        try {
            const result = await agent.Magazines.list(this.requestModel).toPromise();
            runInAction('load magazines', () => {
                this.bitrateMapping = result.items;
                this.totalCount = result.totalCount;
                this.loadingInitial = false;
            });
        } catch (error) {
            runInAction('load magazines error', () => {
                this.loadingInitial = false;
            });
        }
    }

    @action add = async (mapping: MagazineUpdateDto) => {
        try {
            const updateMappingModel: MagazineUpdateDto = {
                ...mapping
            };

            await agent.Magazines.add(updateMappingModel).toPromise();
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    @action update = async (mapping: MagazineUpdateDto) => {
        try {
            const updateMappingModel: MagazineUpdateDto = {
                ...mapping
            };

            await agent.Magazines.update(updateMappingModel).toPromise();
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }

    @action remove = async (mapping: MagazineUpdateDto) => {
        try {
            const updateMappingModel: MagazineUpdateDto = {
                ...mapping
            };

            await agent.Magazines.remove(updateMappingModel).toPromise();
        }
        catch (error) {
            console.log(error);
            throw error;
        }
    }
}
