import React, { useContext, useMemo } from 'react'
import { Grid, Segment, Loader, Button, Container } from 'semantic-ui-react'

import PdfFilesTable from './PdfFilesTable'
import { useObservable, SearchInput, PdfFileStatusButtonsFilter, useParamsListId, refreshWithTimer } from '../Common'
import { RootStoreContext } from '../../app/stores/rootStore'
import { PdfFile, PdfFileRow } from './models'
import PdfFileButtons from './PdfFileButtons'
import PdfFileDetailsView from './PdfFileDetailsView'
import Routes from '../../app/layout/Routes'
import agent from '../../app/api/agent'

export default () => {

    let { pdfFilesStore, jobsStore } = useContext(RootStoreContext)
    let {
        items$,
        details$, isDetailsLoading$,
        searchFilter$, setSearchFilter,
    } = pdfFilesStore
    let {
        startPdfFilesSyncJob, isStartPdfFilesSyncJob$,
    } = jobsStore

    useParamsListId(pdfFilesStore, Routes.PdfFiles())

    let searchFilter: string | null = useObservable(searchFilter$, null)
    let items: PdfFileRow[] | null = useObservable(items$, null)

    let isStartPdfFilesSyncJob: boolean = useObservable(isStartPdfFilesSyncJob$, false)

    let details: PdfFile | null = useObservable(details$, null)
    let isDetailsLoading: boolean = useObservable(isDetailsLoading$, false)

    let lastLogs$ = useMemo(() => {
        return refreshWithTimer(() => agent.Logs.realtimeLastN('PdfFilesSyncService', 1), 1)
    }, [])
    let lastLogs = useObservable(lastLogs$, null);
    let userLastLogs = lastLogs && lastLogs.indexOf('   at ') ? lastLogs.split('   at ')[0] : lastLogs

    return (
        items === null
            ?
            <Loader active={true} content={'Loading...'} />
            :
            <Grid>
                <Grid.Column width={9}>
                    <Container fluid
                        style={{ display: 'flex', alignItems: 'center' }}>
                        <SearchInput searchFilter={searchFilter}
                            setSearchFilter={setSearchFilter}
                            placeholder='Search by file name' />
                        <Button
                            disabled={isStartPdfFilesSyncJob}
                            loading={isStartPdfFilesSyncJob}
                            size='small'
                            onClick={startPdfFilesSyncJob}>
                            Import
                        </Button>
                        {
                            userLastLogs &&
                            <div className='ml-20'>
                                {
                                    userLastLogs.indexOf('Finished successfully') < 0 &&
                                    userLastLogs.indexOf('Exception') < 0 &&
                                    <Loader active inline className='mr-20' />
                                }
                                {userLastLogs}
                            </div>
                        }
                    </Container>
                    <PdfFilesTable />
                </Grid.Column>
                <Grid.Column width={7}>
                    <PdfFileStatusButtonsFilter store={pdfFilesStore}></PdfFileStatusButtonsFilter>
                    {
                        details != null &&
                        <Segment loading={isDetailsLoading}>
                            <PdfFileButtons pdfFile={details}></PdfFileButtons>
                            {
                                details &&
                                <PdfFileDetailsView pdfFile={details} />
                            }
                        </Segment>
                    }
                </Grid.Column>
            </Grid>
    )
}
