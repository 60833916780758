import React from 'react';
import './login-container.scss'

export default (props: {
	innerForm: JSX.Element
}) => {

	let { innerForm } = props

	return (
		<>
			<div className="login-container">
				{innerForm}
			</div>
		</>
	);
}