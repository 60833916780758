import React, { useContext, useEffect, useState } from 'react'
import { Grid, Form, Button, Header, Loader } from 'semantic-ui-react'
import { useParams, useHistory } from 'react-router'
import { combineValidators, isRequired, composeValidators, isNumeric, createValidator } from 'revalidate'
import { Form as FinalForm, Field } from 'react-final-form'
import { FORM_ERROR } from 'final-form'
import './provider.scss'

import { RootStoreContext } from '../../../app/stores/rootStore'
import TextInput from '../../../app/common/form/TextInput'
import SelectInput from '../../../app/common/form/SelectInput'
import { Provider } from './models'
import ErrorMessage from '../../../app/common/form/ErrorMessage'
import { ProviderMode } from '../../../app/models/models'
import Routes from '../../../app/layout/Routes'
import agent from '../../../app/api/agent'

const isPath = createValidator(
    message => value => {
        if (value && value.substring(0, 1) !== "/") {
            return message
        }
    },
    field => `${field} should start from "/"`
)

const ProviderModeOptions = [
    { key: ProviderMode.Disabled, text: ProviderMode[ProviderMode.Disabled], value: ProviderMode.Disabled },
    { key: ProviderMode.Manual, text: ProviderMode[ProviderMode.Manual], value: ProviderMode.Manual },
    { key: ProviderMode.Automatic, text: ProviderMode[ProviderMode.Automatic], value: ProviderMode.Automatic }
]

const validate = combineValidators({
    name: isRequired('Name'),
    tsPath: isPath('TS path'),
    smoothPath: isPath('Smooth path'),
    ftpUrl: isRequired('Ftp url'),
    ftpUser: isRequired('Ftp user'),
    ftpPass: isRequired('Ftp password'),
    ftpPort: composeValidators(isRequired, isNumeric/*, isGreaterThan(0)*/)('Ftp port'),
    ftpProtocol: isRequired('Ftp protocol'),
})

export default () => {

    const rootStore = useContext(RootStoreContext)
    const {
        forceReload,
    } = rootStore.providersStore

    let history = useHistory()
    let id = useParams<{ id: string }>().id
    let [isLoading, setIsLoading] = useState<boolean>(true)
    let [provider, setProvider] = useState<Provider | null>(null)

    useEffect(() => {
        agent.Providers.get(id).toPromise()
            .then(provider => {
                setProvider(provider)
                setIsLoading(true)
            })
    }, [id])

    let onCancelHandler = (id: string) => {
        agent.Providers.delete(id).toPromise()
            .then(() => {
                forceReload()
                history.push(Routes.Providers())
            })
    }

    let onUpdate = (provider: Provider) => {
        return agent.Providers.update(provider).toPromise()
            .then(() => {
                forceReload()
                history.push(Routes.Providers())
            })
            .catch(error => ({ [FORM_ERROR]: error }))
    }

    if (!provider) {
        return <h2>Provider is not found</h2>
    }

    return (
        <Grid style={{ maxWidth: '60rem' }}>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h2'> {provider!.name ? 'Edit provider' : 'Add provider'}</Header>
                    {
                        isLoading && provider === null
                            ?
                            <Loader active={isLoading} content={'Loading provider...'} />
                            :
                            <FinalForm
                                onSubmit={onUpdate}
                                validate={validate}
                                initialValues={provider!}
                                render={({ handleSubmit, submitting, submitError, pristine, dirtySinceLastSubmit }) => {

                                    return (
                                        <Form onSubmit={handleSubmit} loading={submitting} error>
                                            {submitError && !dirtySinceLastSubmit && (
                                                <ErrorMessage error={submitError} />
                                            )}
                                            <Field
                                                width={8}
                                                name='id'
                                                component={TextInput}
                                                hidden={true}
                                                value={provider!.id} />
                                            <Form.Group>
                                                <Field
                                                    width={8}
                                                    name='name'
                                                    component={TextInput}
                                                    placeholder='Provider name'
                                                    value={provider!.name} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Field
                                                    width={8}
                                                    name='tsPath'
                                                    component={TextInput}
                                                    placeholder='TS path'
                                                    value={provider!.tsPath} />
                                                <Field
                                                    width={8}
                                                    name='smoothPath'
                                                    component={TextInput}
                                                    placeholder='Smooth path'
                                                    value={provider!.smoothPath} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Field
                                                    width={8}
                                                    name='ftpUrl'
                                                    component={TextInput}
                                                    placeholder='Ftp url'
                                                    value={provider!.ftpUrl} />
                                                <Field
                                                    width={8}
                                                    name='ftpProtocol'
                                                    component={TextInput}
                                                    placeholder='Ftp protocol'
                                                    value={provider!.ftpProtocol} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Field
                                                    width={8}
                                                    name='ftpUser'
                                                    component={TextInput}
                                                    placeholder='Ftp user name'
                                                    value={provider!.ftpUser} />
                                                <Field
                                                    width={8}
                                                    name='ftpPass'
                                                    component={TextInput}
                                                    type={'password'}
                                                    placeholder='Ftp password'
                                                    value={provider!.ftpPass} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Field
                                                    width={8}
                                                    name='ftpPort'
                                                    component={TextInput}
                                                    type={'number'}
                                                    placeholder='Ftp port'
                                                    value={provider!.ftpPort.toString()} />
                                                <Field
                                                    width={8}
                                                    name='mode'
                                                    component={SelectInput}
                                                    options={ProviderModeOptions}
                                                    placeholder='Mode'
                                                    value={provider!.mode.toString()} />
                                            </Form.Group>
                                            <Button
                                                disabled={(submitError && !dirtySinceLastSubmit) || pristine}
                                                floated='left'
                                                primary
                                                type='submit'
                                                content='Save'
                                            />
                                            {!provider!.name &&
                                                (<Button
                                                    type='button'
                                                    onClick={() => onCancelHandler(provider!.id)}
                                                    floated='left'
                                                    content='Cancel' />)
                                            }
                                        </Form>)
                                }} />
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}