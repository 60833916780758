import agent from "../../../app/api/agent"
import { RootStore } from "../../../app/stores/rootStore"
import { BehaviorSubject, Observable, combineLatest, Subject, of } from "rxjs"
import { startWith, tap, switchMap, shareReplay, map, distinctUntilChanged } from "rxjs/operators"
import { refreshWithTimer } from "../../Common"

export default class LogsStore {

    constructor(private rootStore: RootStore) {
    }

    items$ = refreshWithTimer(() => agent.Logs.list(), 3)
        .pipe(
            map(x => (x && x.keys) || []),
            shareReplay({ refCount: true, bufferSize: 1 }),
        )

    private manualSelectedRowSubject$: Subject<string | null> = new Subject<string | null>()
    manualSelectedRow$: Observable<string | null> = this.manualSelectedRowSubject$.asObservable()

    manualSelectRow = (row: string) => {
        this.manualSelectedRowSubject$.next(row)
    }

    currentRow$: Observable<string | null> =
        combineLatest(
            this.manualSelectedRowSubject$.pipe(startWith(null), distinctUntilChanged()),
            this.items$.pipe(distinctUntilChanged()),
        )
            .pipe(
                map(([selectedRow, items]): string | null => {
                    let updatedSelectedRow = selectedRow
                        && items.find(x => x === selectedRow)

                    return updatedSelectedRow || items[0] || null
                }),
                distinctUntilChanged((x, y) => x === y),
            )

    currentRowId$: Observable<string | null> = this.currentRow$
        .pipe(
            map(x => x),
            distinctUntilChanged(),
        )

    private isDetailsLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    isDetailsLoading$: Observable<boolean> = this.isDetailsLoadingSubject$.asObservable()
    details$: Observable<string | null> =
        combineLatest(
            this.currentRowId$.pipe(tap(() => this.isDetailsLoadingSubject$.next(true))),
        )
            .pipe(
                map(([currentRowId]) => currentRowId),
                switchMap(currentRowId => currentRowId
                    ? refreshWithTimer(() => agent.Logs.getLogs(currentRowId), 3)
                    : of(null)),
                tap(() => this.isDetailsLoadingSubject$.next(false)),
                shareReplay({ bufferSize: 1, refCount: true }),
            )

    lastLog$: Observable<string | null> =
        combineLatest(
            this.currentRowId$.pipe(),
        )
            .pipe(
                map(([currentRowId]) => currentRowId),
                switchMap(currentRowId => currentRowId
                    ? refreshWithTimer(() => agent.Logs.realtimeLastN(currentRowId, 1), 3)
                    : of(null)),
                tap(() => this.isDetailsLoadingSubject$.next(false)),
                shareReplay({ bufferSize: 1, refCount: true }),
            )

}