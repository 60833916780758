import React, { useContext } from 'react'
import { Table, Loader, Container } from 'semantic-ui-react';
import { RootStoreContext } from '../../app/stores/rootStore';
import { useObservable, Paginator, ModifiedDate } from '../Common';
import { PdfFileStatusLabel } from '../Common';
import { PdfFileRow } from './models';

export default () => {

    let { pdfFilesStore } = useContext(RootStoreContext);
    let {
        items$, isPageLoading$,
        currentRowId$, manualSelectRow,
    } = pdfFilesStore;

    let items: PdfFileRow[] = useObservable(items$, []);
    let isPageLoading: boolean = useObservable(isPageLoading$, true);
    let currentRowId: string | null = useObservable(currentRowId$, null);
    //let currentSortField: string | null = useObservable(smoothsStore.sortField$, '');
    //let currentSortAsc: boolean = useObservable(smoothsStore.sortAsc$, true);

    return (
        <Container>
            <Loader active={isPageLoading} />
            <Table celled striped selectable sortable size='small'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3}>File name</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Magazine code</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Year</Table.HeaderCell>
                        <Table.HeaderCell width={1}>Week</Table.HeaderCell>
                        <Table.HeaderCell width={3}>Public name</Table.HeaderCell>
                        <Table.HeaderCell width={2}
                            sorted={'descending'}
                            onClick={() => { }}>
                            Modified
                            </Table.HeaderCell>
                        <Table.HeaderCell width={2}>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items.map((item) => 
                        <Table.Row key={item.id}
                            onClick={() => manualSelectRow(item)}
                            active={currentRowId === item.id}>
                            <Table.Cell className='break-word'>{item.remoteFileName}</Table.Cell>
                            <Table.Cell className='break-word'>{item.magazineCode}</Table.Cell>
                            <Table.Cell>{item.year}</Table.Cell>
                            <Table.Cell>{item.week}</Table.Cell>
                            <Table.Cell className='break-word'>{item.publicName || '---'}</Table.Cell>
                            <Table.Cell title={item.lastModified && new Date(item.lastModified).toLocaleString()}>
                                <ModifiedDate date={item.lastModified} />
                            </Table.Cell>
                            <Table.Cell>
                                <PdfFileStatusLabel status={item.status} error={item.lastErrorMessage} />
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            <Paginator store={pdfFilesStore} />
        </Container>
    )
}