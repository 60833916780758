import React from 'react';
import { Segment, Button, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Routes from './Routes';

const Forbidden = () => {
    return (
        <Segment placeholder>
            <Header icon>
                <Icon name='lock' />
                401 Forbidden. You have no rights to view this info. Please contact administrator to add you permissions.
            </Header>
            <Segment.Inline>
                <Button as={Link} to={Routes.Profile()} primary>
                    Return to profile page
                </Button>
            </Segment.Inline>
        </Segment>
    );
};

export default Forbidden;