import React, { useContext } from 'react'
import { Loader, Container, Card, Image } from 'semantic-ui-react'
import { RootStoreContext } from '../../app/stores/rootStore'
import { useObservable, VSpace, SimplePaginator } from '../Common'
import { LandingPdfFileRow } from './models'
import agent from '../../app/api/agent'

export default () => {

    let { landingStore } = useContext(RootStoreContext)
    let {
        items$, isPageLoading$,
    } = landingStore

    let items: LandingPdfFileRow[] = useObservable(items$, [])
    let isPageLoading: boolean = useObservable(isPageLoading$, true)

    return (
        <Container fluid>
            <VSpace height='1rem' />
            <Loader active={isPageLoading} />
            {
                items.length > 0
                    ?
                    <Card.Group itemsPerRow={8}>
                        {
                            items.map((item, index) =>
                                <Card key={index}
                                    as='a'
                                    target='_blank'
                                    href={agent.Links.magazineRead(item.id, item.magazineSlug, item.year, item.week)}
                                    className='pointer'>
                                    <Image src={agent.Links.magazineThumbnail(item.id, 600, 600)} wrapped ui={false} />
                                    <Card.Content style={{ display: 'flex', justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}>
                                        <Card.Header>{item.magazineName}</Card.Header>
                                    </Card.Content>
                                    <Card.Content style={{ flexGrow: '0' }}>
                                        <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <span>{item.year}</span>
                                            <span>{
                                                item.isAttachment
                                                    ? 'Bilaga'
                                                    : item.isChronicles
                                                        ? 'Årskröniker'
                                                        : `Vecka ${item.week}`
                                            }</span>
                                        </Card.Header>
                                    </Card.Content>
                                </Card>
                            )
                        }
                    </Card.Group>
                    :
                    <p>Nothing is found</p>
            }
            <VSpace height='1.5rem' />
            <div style={{ textAlign: 'center' }}>
                <SimplePaginator store={landingStore} />
            </div>
        </Container >
    )
}