import { observable, computed, action } from "mobx";
import { PageRequest } from "../models/request-response-base";
import { RootStore } from "./rootStore";

export default class PagingStoreBase<T extends PageRequest> {

    public ITEMS_PER_PAGE: number = 100;
    constructor(protected rootStore: RootStore) {
    }

    @observable page: number = 1;
    @observable totalCount: number | null = null;
    @observable requestModel: T = {
        skip: 0,
        take: this.ITEMS_PER_PAGE
    } as T;


    loadPage(): Promise<void> {
        throw new Error('loadPage is not implemented');
    }

    @action setPage = (pageNumber: number | string | undefined) => {

        if (pageNumber === undefined || isNaN(+pageNumber)) {
            throw Error(`Unsupported page number ${pageNumber}`)
        }

        this.page = +pageNumber;
        this.requestModel = { ...this.requestModel, skip: this.ITEMS_PER_PAGE * (this.page - 1) }
        this.loadPage().then();
    }

    @action setSearch = (search: string) => {

        this.page = 1;
        this.totalCount = 0;
        this.requestModel = { ...this.requestModel, skip: 0, search: search };
        this.loadPage().then();
    }

    @computed get getTotalPages(): number {

        return (Math.trunc((this.totalCount || 0) / this.ITEMS_PER_PAGE) +
            (!!((this.totalCount || 0) % this.ITEMS_PER_PAGE) ? 1 : 0))
    }
}
