export enum MagazineType {
    Normal = 1,
    Attachment = 2,
    Chronicles = 3,
}

export enum ProviderMode {
    Disabled = 0,
    Manual = 1,
    Automatic = 2,
}

export enum LogLevel {
    Debug = 1,
    Info = 2,
    Warning = 3,
    Error = 4,
}

export enum Priority {
    /// <summary>
    /// Should not be processed in this priority
    /// </summary>
    Skip = 0,
    Low = 1,
    Medium = 2,
    High = 3,
}

export enum NavigationEntityType {
    PdfFile = 7,
}

export interface Navigation {
    entityId: string
    entityType: NavigationEntityType
    entityTitle: string
}

export interface StatusCountRequest {
}

export interface StatusCount<T> {
    status: T
    count: number
}

export interface UpdatePriorityRequest {
    id: string;
    priority: Priority;
}

export interface UpdateStatusRequest<T> {
    id: string,
    status: T,
}
