import React, { useContext, useEffect, useState } from 'react'
import { RootStoreContext } from '../../../app/stores/rootStore'
import { Grid, Loader, Table, Menu, Pagination, Header, Button } from 'semantic-ui-react'
import { Magazine } from './models'
import { toast } from 'react-toastify'
import { observer } from 'mobx-react-lite'

let defaultMagazine = {
    id: '00000000-0000-0000-0000-000000000000',
    slug: '',
    name: '',
    codes: '',
    attachmentCodes: '',
    chroniclesCodes: '',
}

export default observer(() => {

    let rootStore = useContext(RootStoreContext)
    let { loadPage, bitrateMapping, getTotalPages, setPage, page, loadingInitial, totalCount,
        add, update, remove } = rootStore.magazinesStore
    let [editItem, setEditItem] = useState<Magazine | null>(null)
    let [newItem, setNewItem] = useState<Magazine>(defaultMagazine)

    let handleNextPage = (page: string | number | undefined) => {
        setPage(page)
    }

    useEffect(() => {
        loadPage()
    }, [loadPage])

    let addClickHandler = (bitrateMapping: Magazine) => {
        add(bitrateMapping)
            .then(() => {
                toast.success('Magazine have been added successfully')
                loadPage()
                setNewItem(defaultMagazine)
            })
            .catch(error => toast.error('Error occurred on adding magazine'))
    }

    let editClickHandler = (bitrateMapping: Magazine) => {
        setEditItem(bitrateMapping)
    }

    let saveClickHandler = (bitrateMapping: Magazine) => {
        update(bitrateMapping)
            .then(() => {
                toast.success('Magazine have been saved successfully')
                loadPage()
            })
            .catch(error => toast.error('Error occurred on saving magazine'))
    }

    let cancelClickHandler = (bitrateMapping: Magazine) => {
        setEditItem(null)
    }

    let removeClickHandler = (bitrateMapping: Magazine) => {
        if (window.confirm('Are you sure?')) {
            remove(bitrateMapping)
                .then(() => {
                    toast.success('Magazine have been removed successfully')
                    loadPage()
                })
                .catch(error => toast.error('Error occurred on removing magazine'))
        }
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column style={{overflowX: 'auto'}}>
                    <Header as='h2'>Magazines ({totalCount})</Header>

                    {
                        loadingInitial && totalCount === null
                            ?
                            <Loader active={loadingInitial} content={'Loading...'} />
                            :
                            <Table celled singleLine size='small'>

                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={2}>Slug for URL</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Name</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Codes, separated by "|"</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Attachment codes, separated by "|"</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Chronicles codes, separated by "|"</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    <BitrateMappingRow item={newItem}
                                        mode={'add'}
                                        onAdd={addClickHandler} />
                                    {bitrateMapping.map((item) =>
                                        <BitrateMappingRow key={item.id}
                                            item={item}
                                            mode={editItem === item ? 'edit' : 'view'}
                                            onEdit={editClickHandler}
                                            onSave={saveClickHandler}
                                            onCancel={cancelClickHandler}
                                            onRemove={removeClickHandler} />
                                    )}
                                </Table.Body>

                                {
                                    getTotalPages <= 1
                                        ? <></>
                                        : <Table.Footer>
                                            <Table.Row>
                                                <Table.HeaderCell colSpan='3'>
                                                    <Menu floated='right' pagination>
                                                        <Pagination
                                                            boundaryRange={0}
                                                            defaultActivePage={page}
                                                            ellipsisItem={null}
                                                            firstItem={null}
                                                            lastItem={null}
                                                            siblingRange={1}
                                                            size='mini'
                                                            onPageChange={(event, data) => handleNextPage(data.activePage)}
                                                            totalPages={getTotalPages} />
                                                    </Menu>
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Footer>
                                }
                            </Table>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
})

function BitrateMappingRow(props: {
    item: Magazine,
    mode: 'view' | 'edit' | 'add',
    onAdd?: (newItem: Magazine) => void,
    onEdit?: (newItem: Magazine) => void,
    onSave?: (newItem: Magazine) => void,
    onCancel?: (newItem: Magazine) => void,
    onRemove?: (newItem: Magazine) => void,
}) {

    let { item, mode } = props
    let isView = mode === 'view'
    let [editItem, setEditItem] = useState<Magazine>(item && { ...item })

    let handleInputChange = (fieldName: string) => (event: any) => {
        let value = event.target.type === 'checkbox' ? event.target.checked : event.target.value
        setFieldValue(fieldName, value)
    }

    let setFieldValue = (fieldName: string, value: any) => {
        let newEditItem = {
            ...editItem,
            [fieldName]: value
        }

        setEditItem(newEditItem)
    }

    let isValid = (editItem: Magazine): boolean =>
        (editItem.slug && editItem.name) ? true : false

    return (
        <Table.Row key={item.id}>
            <Table.Cell>
                {
                    isView
                        ? item.slug
                        : <UiInput value={editItem.slug}
                            onChange={handleInputChange('slug')}
                            type="text" autoComplete={'off'} />
                }
            </Table.Cell>
            <Table.Cell>
                {
                    isView
                        ? item.name
                        : <UiInput value={editItem.name}
                            onChange={handleInputChange('name')}
                            type="text" autoComplete={'off'} />
                }
            </Table.Cell>
            <Table.Cell>
                {
                    isView
                        ? item.codes
                        : <UiInput value={editItem.codes}
                            onChange={handleInputChange('codes')}
                            type="text" autoComplete={'off'} />
                }
            </Table.Cell>
            <Table.Cell>
                {
                    isView
                        ? item.attachmentCodes
                        : <UiInput value={editItem.attachmentCodes}
                            onChange={handleInputChange('attachmentCodes')}
                            type="text" autoComplete={'off'} />
                }
            </Table.Cell>
            <Table.Cell>
                {
                    isView
                        ? item.chroniclesCodes
                        : <UiInput value={editItem.chroniclesCodes}
                            onChange={handleInputChange('chroniclesCodes')}
                            type="text" autoComplete={'off'} />
                }
            </Table.Cell>
            <Table.Cell>
                {
                    mode === 'view' &&
                    <Button primary className="mini"
                        onClick={() => props.onEdit && props.onEdit(item)}>Edit</Button>
                }
                {
                    mode === 'edit' &&
                    <Button primary className="mini"
                        disabled={!isValid(editItem)}
                        onClick={() => props.onSave && props.onSave(editItem)}>Save</Button>
                }
                {
                    mode === 'add' &&
                    <Button primary className="mini"
                        disabled={!isValid(editItem)}
                        onClick={() => props.onAdd && props.onAdd(editItem)}>Add</Button>
                }
                {
                    mode === 'edit' &&
                    <Button className="mini"
                        onClick={() => props.onCancel && props.onCancel(item)}>Cancel</Button>
                }
                {
                    mode === 'view' &&
                    <Button negative className="mini"
                        onClick={() => props.onRemove && props.onRemove(item)}>Remove</Button>
                }
            </Table.Cell>
        </Table.Row >
    )
}

function UiInput(props: any) {

    return (
        <div className="ui input mini" style={{ width: '100%' }}>
            <input {...props} />
        </div>
    )
}