import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export class PageRequest implements IPagedRequest, ISortedParams, IFilteredParams {
    skip: number = 0;
    take: number = 0;
    sortField: string | null = null;
    sortAsc: boolean = true;
    filters: Filter[] = [];
}

export interface IPagedRequest {
    skip: number;
    take: number;
}

export interface IPagedParams {
    pageIndex: number;
}

export interface ISortedParams {
    sortField: string | null;
    sortAsc: boolean;
}

export interface IFilteredParams {
    filters: Filter[];
}

export interface Filter {
    field: string;
    // condition: FilterCondition;
    value: string;
    //values?: string[];
}

export enum FilterCondition {
    FieldContainsValue = 'FieldContainsValue',
}

export function defaultIfNull<T>(defaultValue: T) {

    return (observable: Observable<T>): Observable<T> => observable
        .pipe(
            map(x => x === null ? defaultValue : x),
        );
}
