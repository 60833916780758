import agent from '../../../app/api/agent'
import { RootStore } from '../../../app/stores/rootStore'
import PagedStoreBase, { Filter } from '../../../app/stores/pagedStoreBase'
import SessionStore from '../../../app/stores/sessionStore'
import { combineLatest, Observable, BehaviorSubject, Subject } from 'rxjs'
import { distinctUntilChanged, startWith, map, tap, switchMap, shareReplay, debounceTime } from 'rxjs/operators'
import { PageResponse } from '../../../app/models/PageResponse'
import { ProviderRow, ProvidersParams, ProviderRowField, ProviderRowsRequest } from './models'
import { toast } from 'react-toastify'
import { refreshWithTimer } from '../../Common'
import { defaultIfNull } from '../../../app/models/request-response-base'

export default class ProvidersStore extends PagedStoreBase<ProviderRow, ProvidersParams> {
    storageKey: string = 'ProvidersStore_filters_v2'
    defaultSearchFilter: string = ''
    defaultSortField: ProviderRowField = ProviderRowField.Title
    defaultSortAsc: boolean = true
    defaultFilters: Filter[] = []

    constructor(private rootStore: RootStore) {
        super()

        this.setSearchFilter(this.sessionStore.getPropertyValue(x => x.searchFilter, this.defaultSearchFilter), false)
        this.setPageIndex(this.sessionStore.getPropertyValue(x => x.pageIndex, this.firstPageIndex))
        this.setFilters(this.sessionStore.getPropertyValue(x => x.filters, this.defaultFilters), false)
        this.setSortField(this.sessionStore.getPropertyValue(x => x.sortField, this.defaultSortField), false)
        this.setSortAsc(this.sessionStore.getPropertyValue(x => x.sortAsc, this.defaultSortAsc), false)
    }

    private sessionStore: SessionStore<ProvidersParams> = new SessionStore<ProvidersParams>(this.storageKey)

    private searchFilterSubject$: BehaviorSubject<string | null>
        = new BehaviorSubject<string | null>(this.defaultSearchFilter)
    searchFilter$: Observable<string | null> = this.searchFilterSubject$.asObservable()

    setSearchFilter = (searchFilter: string | null, resetPage: boolean = true) => {
        resetPage && this.resetPage()
        this.searchFilterSubject$.next(searchFilter)
    }

    private isPageLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    isPageLoading$: Observable<boolean> = this.isPageLoadingSubject$.asObservable()

    params$ = combineLatest(
        this.pageIndex$.pipe(debounceTime(300), distinctUntilChanged()),
        this.searchFilter$.pipe(debounceTime(300), distinctUntilChanged()),
        this.filters$.pipe(debounceTime(300), distinctUntilChanged()),
        this.sortField$.pipe(debounceTime(300), distinctUntilChanged()),
        this.sortAsc$.pipe(debounceTime(300), distinctUntilChanged()),
    )

    setLoading = (loading: boolean) => tap(() => this.isPageLoadingSubject$.next(loading))

    page$ = combineLatest(
        this.params$,
        this.forceReload$.pipe(startWith(true)),
    )
        .pipe(
            map<any, ProvidersParams>(([[pageIndex, searchFilter, filters, sortField, sortAsc]]): ProvidersParams => ({
                pageIndex: pageIndex,
                searchFilter: searchFilter,
                filters: filters,
                sortField: sortField,
                sortAsc: sortAsc,
            })),
            debounceTime(0),
            tap(params => {
                this.sessionStore.saveValue(params)
            }),
            map((params: ProvidersParams): ProviderRowsRequest => ({
                searchFilter: params.searchFilter,
                skip: this.pageSize * (params.pageIndex - 1),
                sortField: params.sortField,
                sortAsc: params.sortAsc,
                take: this.pageSize,
                filters: params.filters,
            })),
            tap(() => this.isPageLoadingSubject$.next(true)),
            switchMap(query => refreshWithTimer(() => agent.Providers.list(query), 3)),
            tap(() => this.isPageLoadingSubject$.next(false)),
            defaultIfNull(PageResponse.Empty<ProviderRow>()),
            shareReplay({ bufferSize: 1, refCount: true }),
        )

    items$ = this.page$
        .pipe(
            map(page => page.items),
        )

    totalCount$ = this.page$
        .pipe(
            map(page => page.totalCount)
        )

    private manualSelectedRowSubject$: Subject<ProviderRow | null> = new Subject<ProviderRow | null>()
    manualSelectedRow$: Observable<ProviderRow | null> = this.manualSelectedRowSubject$.asObservable()

    manualSelectRow = (row: ProviderRow) => {
        this.manualSelectedRowSubject$.next(row)
    }

    currentRow$: Observable<ProviderRow | null> =
        combineLatest(
            this.manualSelectedRowSubject$.pipe(startWith(null), distinctUntilChanged()),
            this.items$.pipe(distinctUntilChanged()),
        )
            .pipe(
                map(([selectedRow, items]): ProviderRow => {
                    let updatedSelectedRow = selectedRow
                        && items.find(x => x.id === selectedRow.id)

                    return updatedSelectedRow || items[0] || null
                }),
                distinctUntilChanged((x, y) => x && y && x.id === y.id),
            )

    currentRowId$: Observable<string | null> = this.currentRow$
        .pipe(
            map(x => x && x.id),
            distinctUntilChanged(),
        )

    isRemoving$ = new BehaviorSubject<boolean>(false)
    remove = (id: string): Promise<void> => {
        this.isRemoving$.next(true)
        return agent.Providers
            .delete(id)
            .toPromise()
            .then(
                () => {
                    this.forceReload()
                },
                error => {
                    toast.error('Error occurred on deleting provider')
                    throw error
                })
            .finally(() => this.isRemoving$.next(false))
    }

    isAdding$ = new BehaviorSubject<boolean>(false)
    add = (): Promise<string> => {
        this.isAdding$.next(true)
        return agent.Providers
            .add()
            .toPromise()
            .then(
                (id: string) => {
                    this.forceReload()

                    return id
                },
                error => {
                    toast.error('Error occurred on deleting provider')
                    throw error
                })
            .finally(() => this.isAdding$.next(false))
    }
}