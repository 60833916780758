import agent from '../../app/api/agent'
import { RootStore } from '../../app/stores/rootStore'
import PagedStoreBase, { Filter } from '../../app/stores/pagedStoreBase'
import SessionStore from '../../app/stores/sessionStore'
import { combineLatest, Observable, BehaviorSubject, Subject, of } from 'rxjs'
import { distinctUntilChanged, startWith, map, tap, switchMap, shareReplay, debounceTime } from 'rxjs/operators'
import { PageResponse } from '../../app/models/PageResponse'
import { PdfFileStatus, PdfFileRow, PdfFileRowField, PdfFileRowsRequest, PdfFilesParams } from './models'
import { PdfFile } from './models'
import { defaultIfNull } from '../../app/models/request-response-base'
import { UpdateStatusRequest, StatusCount } from '../../app/models/models'
import { toast } from 'react-toastify'
import { refreshWithTimer } from '../Common'

export default class PdfFilesStore extends PagedStoreBase<PdfFileRow, PdfFilesParams> {
    storageKey: string = 'PdfFilesStore_filters_v1'
    defaultSearchFilter: string = ''
    defaultStatusFilter: PdfFileStatus | null = null
    defaultSortField: PdfFileRowField = PdfFileRowField.Title
    defaultSortAsc: boolean = true
    defaultFilters: Filter[] = []

    constructor(private rootStore: RootStore) {
        super()

        this.setSearchFilter(this.sessionStore.getPropertyValue(x => x.searchFilter, this.defaultSearchFilter), false)
        this.setStatusFilter(this.sessionStore.getPropertyValue(x => x.statusFilter, this.defaultStatusFilter), false)
        this.setPageIndex(this.sessionStore.getPropertyValue(x => x.pageIndex, this.firstPageIndex))
        this.setFilters(this.sessionStore.getPropertyValue(x => x.filters, this.defaultFilters), false)
        this.setSortField(this.sessionStore.getPropertyValue(x => x.sortField, this.defaultSortField), false)
        this.setSortAsc(this.sessionStore.getPropertyValue(x => x.sortAsc, this.defaultSortAsc), false)
    }

    private sessionStore: SessionStore<PdfFilesParams> = new SessionStore<PdfFilesParams>(this.storageKey)

    private searchFilterSubject$: BehaviorSubject<string | null>
        = new BehaviorSubject<string | null>(this.defaultSearchFilter)
    searchFilter$: Observable<string | null> = this.searchFilterSubject$.asObservable()

    setSearchFilter = (searchFilter: string | null, resetPage: boolean = true) => {
        resetPage && this.resetPage()
        this.searchFilterSubject$.next(searchFilter)
    }

    private statusFilterSubject$: BehaviorSubject<PdfFileStatus | null>
        = new BehaviorSubject<PdfFileStatus | null>(this.defaultStatusFilter)
    statusFilter$: Observable<PdfFileStatus | null> = this.statusFilterSubject$.asObservable()

    setStatusFilter = (statusFilter: PdfFileStatus | null, resetPage: boolean = true) => {
        resetPage && this.resetPage()
        this.statusFilterSubject$.next(statusFilter)
    }

    private isPageLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    isPageLoading$: Observable<boolean> = this.isPageLoadingSubject$.asObservable()

    params$ = combineLatest(
        this.pageIndex$.pipe(debounceTime(300), distinctUntilChanged()),
        this.searchFilter$.pipe(debounceTime(300), distinctUntilChanged()),
        this.statusFilter$.pipe(debounceTime(300), distinctUntilChanged()),
        this.filters$.pipe(debounceTime(300), distinctUntilChanged()),
        this.sortField$.pipe(debounceTime(300), distinctUntilChanged()),
        this.sortAsc$.pipe(debounceTime(300), distinctUntilChanged()),
    )

    setLoading = (loading: boolean) => tap(() => this.isPageLoadingSubject$.next(loading))

    page$ = combineLatest(
        this.params$,
        this.forceReload$.pipe(startWith(true)),
    )
        .pipe(
            map<any, PdfFilesParams>(([[pageIndex, searchFilter, statusFilter, filters, sortField, sortAsc]]): PdfFilesParams => ({
                pageIndex: pageIndex,
                searchFilter: searchFilter,
                statusFilter: statusFilter,
                filters: filters,
                sortField: sortField,
                sortAsc: sortAsc,
            })),
            debounceTime(0),
            tap(params => {
                this.sessionStore.saveValue(params)
            }),
            map((params: PdfFilesParams): PdfFileRowsRequest => ({
                searchFilter: params.searchFilter,
                statusFilter: params.statusFilter,
                skip: this.pageSize * (params.pageIndex - 1),
                sortField: params.sortField,
                sortAsc: params.sortAsc,
                take: this.pageSize,
                filters: params.filters,
            })),
            tap(() => this.isPageLoadingSubject$.next(true)),
            switchMap(query => refreshWithTimer(() => agent.PdfFiles.list(query), 3)),
            tap(() => this.isPageLoadingSubject$.next(false)),
            defaultIfNull(PageResponse.Empty<PdfFileRow>()),
            shareReplay({ bufferSize: 1, refCount: true }),
        )

    items$ = this.page$
        .pipe(
            map(page => page.items),
        )

    totalCount$ = this.page$
        .pipe(
            map(page => page.totalCount)
        )

    countByStatus$: Observable<StatusCount<PdfFileStatus>[]>
        = combineLatest(
            this.forceReload$.pipe(startWith(true)),
        )
            .pipe(
                map(() => ({
                })),
                debounceTime(0),
                switchMap(query => refreshWithTimer(() => agent.PdfFiles.statusCount(query), 3)),
                shareReplay({ bufferSize: 1, refCount: true }),
            )

    private manualSelectedRowSubject$: Subject<PdfFileRow | null> = new Subject<PdfFileRow | null>()
    manualSelectedRow$: Observable<PdfFileRow | null> = this.manualSelectedRowSubject$.asObservable()

    manualSelectRow = (row: PdfFileRow) => {
        this.manualSelectedRowSubject$.next(row)
    }

    currentRow$: Observable<PdfFileRow | null> =
        combineLatest(
            this.manualSelectedRowSubject$.pipe(startWith(null), distinctUntilChanged()),
            this.items$.pipe(distinctUntilChanged()),
        )
            .pipe(
                map(([selectedRow, items]): PdfFileRow => {
                    let updatedSelectedRow = selectedRow
                        && items.find(x => x.id === selectedRow.id)

                    return updatedSelectedRow || items[0] || null
                }),
                distinctUntilChanged((x, y) => x && y && x.id === y.id),
            )

    currentRowId$: Observable<string | null> = this.currentRow$
        .pipe(
            map(x => x && x.id),
            distinctUntilChanged(),
        )


    protected detailsChangedSubject$: Subject<boolean> = new Subject<boolean>()
    detailsChanged$: Observable<boolean> = this.detailsChangedSubject$.asObservable()

    detailsChanged = () => {
        this.detailsChangedSubject$.next(true)
    }

    private isDetailsLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    isDetailsLoading$: Observable<boolean> = this.isDetailsLoadingSubject$.asObservable()
    details$: Observable<PdfFile | null> =
        combineLatest(
            this.currentRowId$.pipe(tap(() => this.isDetailsLoadingSubject$.next(true))),
            this.detailsChanged$.pipe(startWith(true)),
        )
            .pipe(
                map(([currentRowId]) => currentRowId),
                switchMap(currentRowId => currentRowId
                    ? refreshWithTimer(() => agent.PdfFiles.get(currentRowId), 3)
                    : of(null)),
                tap(() => this.isDetailsLoadingSubject$.next(false)),
                shareReplay({ bufferSize: 1, refCount: true }),
            )

    isUpdating$ = new BehaviorSubject<boolean>(false)
    updateStatus = (request: UpdateStatusRequest<PdfFileStatus>): Promise<void> => {
        this.isUpdating$.next(true)
        return agent.PdfFiles
            .updateStatus(request)
            .toPromise()
            .then(
                () => {
                    this.forceReload()
                },
                error => {
                    toast.error('Error occurred on changing status')
                    throw error
                })
            .finally(() => this.isUpdating$.next(false))
    }
}