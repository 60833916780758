import React from 'react'
import { Select, DropdownProps } from 'semantic-ui-react'

export const valueToStr = (value: number | null): string => value === null ? 'null' : value.toString()
export const strToValue = (str: string): number | null => str === 'null' ? null : Number(str)

export const options = [
    { value: valueToStr(null), text: 'Alla veckor' }, // text: 'All weeks' },
]

for (let i = 1; i <= 53; i++) {
    options.push({ value: valueToStr(i), text: i.toString() })
}

export default (props: {
    filter: number | null,
    setFilter: (filter: number | null) => void,
    placeholder?: string,
}) => {
    let { filter, setFilter, placeholder } = props;

    return (
        <Select
            options={options}
            placeholder={placeholder}
            value={valueToStr(filter)}
            onChange={(event: any, data: DropdownProps) => setFilter(strToValue(data.value as string))} />
    )
}
