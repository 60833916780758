export default {

    Landing: () => ``,
    PdfFiles: () => `/import`,
    PdfFile: (id: string) => `/import/${id}`,
    Magazines: () => `/admin/magazines`,
    Widgets: () => `/admin/widgets`,

    Providers: () => `/admin/providers`,
    Provider: (id: string) => `/admin/providers/${id}`,
    ProviderEdit: (id: string) => `/admin/providers/${id}/edit`,

    Users: () => `/admin/users`,
    User: (id: string) => `/admin/users/${id}`,
    UserEdit: (id: string) => `/admin/users/${id}/edit`,

    Profile: () => `/profile`,
    Login: () => `/login`,
    Forbidden: () => `/forbidden`,

    Logs: () => `/admin/logs`,
}
