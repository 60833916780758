import React, { useContext } from 'react'
import { Grid, Loader, Button, Container, Icon } from 'semantic-ui-react'
import { RootStoreContext } from '../../../app/stores/rootStore'
import { useParamsListId, SearchInput, useObservable } from '../../Common'
import Routes from '../../../app/layout/Routes'
import { ProviderRow } from './models'
import ProvidersTable from './ProvidersTable'

export default () => {

    let { providersStore: providerStore } = useContext(RootStoreContext)
    let {
        items$, forceReload,
        searchFilter$, setSearchFilter,
    } = providerStore

    useParamsListId(providerStore, Routes.Providers())

    let searchFilter: string | null = useObservable(searchFilter$, null)
    let items: ProviderRow[] | null = useObservable(items$, null)

    return (
        items === null
            ?
            <Loader active={true} content={'Loading...'} />
            :
            <Grid>
                <Grid.Column width={8}>
                    <Container fluid
                        style={{ display: 'flex', alignItems: 'center' }}>
                        <SearchInput searchFilter={searchFilter}
                            setSearchFilter={setSearchFilter}
                            placeholder='Search by name' />
                        <Button
                            icon
                            primary
                            size='small'
                            onClick={() => forceReload()}>
                            <Icon name='refresh'></Icon>
                        </Button>
                    </Container>
                    <ProvidersTable />
                </Grid.Column>
            </Grid>
    )
}
