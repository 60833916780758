import React, { useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Header } from 'semantic-ui-react';
import { FORM_ERROR } from 'final-form';
import { combineValidators, isRequired } from 'revalidate';
import { Link } from 'react-router-dom';
import TextInput from '../../app/common/form/TextInput';
import { RootStoreContext } from '../../app/stores/rootStore';
import { IRegisterUserModel } from './models';
import ErrorMessage from '../../app/common/form/ErrorMessage';
import LoginContainer from './LoginContainer';

const validate = combineValidators({
	email: isRequired('Email'),
	password: isRequired('Password')
});

const LoginForm = () => {
	const rootStore = useContext(RootStoreContext);
	const { login } = rootStore.userStore;

	return (
		<LoginContainer innerForm={
			<FinalForm
				onSubmit={(values: IRegisterUserModel) =>
					login(values).catch(error => ({
						[FORM_ERROR]: error
					}))
				}
				validate={validate}
				render={({
					handleSubmit,
					submitting,
					submitError,
					invalid,
					pristine,
					dirtySinceLastSubmit
				}) => (
						<Form onSubmit={handleSubmit} error>
							<Header
								as='h2'
								content='Login'
								color='teal'
								textAlign='center' />
							<Field name='email' component={TextInput} placeholder='Email' />
							<Field
								name='password'
								component={TextInput}
								placeholder='Password'
								type='password' />
							{submitError && /*!dirtySinceLastSubmit &&*/ (
								<ErrorMessage
									error={submitError}
									text={submitError.statusText === 'Unauthorized' ? 'Invalid email or password' : submitError.message} />
							)}
							{/*
							<Link className='login-link' to="/register">Register user</Link>
							 */}
							<Link className='login-link' to="/recover-password">Forgot Password</Link>
							<Button className="login-button"
								disabled={false/*(invalid && !dirtySinceLastSubmit) || pristine*/}
								loading={submitting}
								color='teal'
								content='Login'
								fluid />
						</Form>
					)} />}
		/>
	);
};

export default LoginForm;
