import { RootStore } from './rootStore';
import { observable, action, reaction } from 'mobx';
import { combineLatest } from 'rxjs';
import SessionStore from './sessionStore';
import { map, debounceTime, tap, shareReplay } from 'rxjs/operators';
import agent from '../api/agent';

interface CommonParams {
}

export default class CommonStore {

    storageKey: string = 'CommonStore_v1'

    private sessionStore: SessionStore<CommonParams> = new SessionStore<CommonParams>(this.storageKey)

    constructor(private rootStore: RootStore) {
        this.rootStore = rootStore;

        combineLatest(
        )
            .pipe(
                map<any, CommonParams>((): CommonParams => ({
                })),
                debounceTime(0),
                tap(params => {
                    this.sessionStore.saveValue(params)
                }),
            )
            .subscribe()

        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('jwt', token);
                } else {
                    window.localStorage.removeItem('jwt')
                }
            }
        )
    }

    @observable token: string | null = window.localStorage.getItem('jwt');
    @observable appLoaded = false;

    @action setToken = (token: string | null) => {
        this.token = token;
    }

    @action setAppLoaded = () => {
        this.appLoaded = true;
    }

    featureToggle$ = agent.Public.featureToggle()
        .pipe(
            shareReplay(1),
        )
}