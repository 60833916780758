import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FormFieldProps, Form, Label, Checkbox } from 'semantic-ui-react';

interface IProps extends FieldRenderProps<boolean, HTMLElement>, FormFieldProps { }

const CheckboxInput: React.FC<IProps> = ({
	input,
	width,
	placeholder,
	meta: { touched, error }
}) => {
	return (
		<Form.Field error={touched && !!error} width={width}>
			<Checkbox checked={input.value} label={placeholder} onChange={(e, data) => input.onChange(data.checked)} />
			{touched && error && (
				<Label basic color='red'>
					{error}
				</Label>
			)}
		</Form.Field>
	);
};

export default CheckboxInput;
