import { RootStore } from "./rootStore";
import { observable, action } from "mobx";
import { StrictModalProps } from "semantic-ui-react";

interface IConfirmModal extends StrictModalProps {
    onYes?: () => void | null,
    onCancel?: () => void | null,
    onClose?: () => void,
    header?: string,
    text?: string
}

export default class ConfirmModalStore {

    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }

    @observable modalProps: IConfirmModal = {
        open: false,
        onYes: undefined,
        onCancel: undefined,
        onClose: undefined,
        header: undefined,
        text: undefined
    }
    
    @action openModal = (header: string,  text: string, onYes : (() => void) | undefined  = undefined)=> {
        this.modalProps.open = true;
        this.modalProps.text = text;
        this.modalProps.onYes = onYes;
        this.modalProps.header = header;
    }

    @action closeModal = () => {
        this.modalProps.open = false;
        this.modalProps.onYes = undefined;
        this.modalProps.onCancel = undefined;
        this.modalProps.onClose = undefined;
        this.modalProps.header = undefined;
        this.modalProps.text = undefined;
    }
}