import agent from '../../app/api/agent'
import { RootStore } from '../../app/stores/rootStore'
import { Observable, BehaviorSubject } from 'rxjs'
import { toast } from 'react-toastify'

export default class JobsStore {

    constructor(private rootStore: RootStore) {
    }

    startJob = (jobName: string, apiMethod: () => Observable<boolean>, isStarting$: BehaviorSubject<boolean>): () => Promise<void> =>
        () => {
            isStarting$.next(true)
            return apiMethod().toPromise().then(
                success => {
                    if (success) {
                        toast.success(`${jobName} started successfully`)
                    }
                    else {
                        toast.warn(`Can not start ${jobName}`)
                    }
                },
                error => {
                    toast.error(`Error occurred on starting ${jobName}`)
                    throw error
                })
                .finally(() => isStarting$.next(false))
        }

    private isStartPdfFilesSyncJobSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    isStartPdfFilesSyncJob$: Observable<boolean> = this.isStartPdfFilesSyncJobSubject$.asObservable()
    startPdfFilesSyncJob = this.startJob('Import job', agent.Jobs.startPdfFilesSyncJob, this.isStartPdfFilesSyncJobSubject$)

    private isStartPdfFileJobSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    isStartPdfFileJob$: Observable<boolean> = this.isStartPdfFileJobSubject$.asObservable()
    startPdfFileJob = this.startJob('Import job', agent.Jobs.startPdfFileJob, this.isStartPdfFileJobSubject$)
}
