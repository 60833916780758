import React, { useContext } from 'react'
import { Grid, Loader, Table } from 'semantic-ui-react'

import { useObservable } from '../../Common'
import { RootStoreContext } from '../../../app/stores/rootStore'

export default () => {

    let { logsStore } = useContext(RootStoreContext)
    let {
        items$,
        details$,
        lastLog$,
        manualSelectRow, currentRowId$
    } = logsStore

    let items: string[] | null = useObservable(items$, null)

    let details: string | null = useObservable(details$, null)
    let lastLog: string | null = useObservable(lastLog$, null)
    let currentRowId: string | null = useObservable(currentRowId$, null)

    return (
        items === null
            ?
            <Loader active={true} content={'Loading...'} />
            :
            <Grid>
                <Grid.Column width={8}>
                    <Table celled striped selectable sortable size='small'>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell width={4}>Log</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>

                        <Table.Body>
                            {items.map((item) =>
                                <Table.Row key={item}
                                    onClick={() => manualSelectRow(item)}
                                    active={currentRowId === item}>
                                    <Table.Cell>{item}</Table.Cell>
                                </Table.Row>
                            )}
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={8}>
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                        {lastLog}
                    </p>
                    <p style={{ whiteSpace: 'pre-wrap' }}>
                        {details}
                    </p>
                </Grid.Column>
            </Grid>
    )
}
