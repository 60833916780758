import React, { useContext } from 'react'
import { Loader, Container, Header } from 'semantic-ui-react'
import { RootStoreContext } from '../../app/stores/rootStore'
import { useObservable, VSpace } from '../Common'
import { WidgetMagazineRow } from './models'

export default () => {

    let { widgetsStore } = useContext(RootStoreContext)
    let {
        magazines$, isMagazinesLoading$,
        currentRow$, manualSelectRow,
    } = widgetsStore

    let magazines: WidgetMagazineRow[] | null = useObservable(magazines$, [])
    let isMagazinesLoading: boolean = useObservable(isMagazinesLoading$, true)
    let currentRow: WidgetMagazineRow | null = useObservable(currentRow$, null)

    return (
        <Container fluid>
            <Header as='h3'>Magazines</Header>
            <Loader active={isMagazinesLoading} />
            {
                magazines && magazines.length > 0 &&
                magazines.map((item, index) =>
                    <div key={index}
                        className='pointer'>
                        <span onClick={() => manualSelectRow(item)}>
                            {
                                currentRow && item.id === currentRow.id
                                    ? <b>{item.name}</b>
                                    : item.name
                            }
                        </span>
                    </div>
                )
            }
            <VSpace height={10} />
        </Container >
    )
}