import React, { useContext } from 'react'
import { DropdownProps, Select } from 'semantic-ui-react'
import { RootStoreContext } from '../../app/stores/rootStore'
import { useObservable } from '../Common'
import { LandingMagazineRow } from './models'

export const valueToStr = (value: string | null): string => value === null ? 'null' : value
export const strToValue = (str: string): string | null => str === 'null' ? null : str

export default () => {
    let { landingStore } = useContext(RootStoreContext)
    let {
        magazines$,
        currentMagazine$, manualSelectMagazineSlug,
    } = landingStore

    let magazines: LandingMagazineRow[] = useObservable(magazines$, [])
    let currentMagazine: LandingMagazineRow | null = useObservable(currentMagazine$, null)

    let options = [
        { value: valueToStr(null), text: 'Alla tidningar' }, // text: 'All magazines' },
        ...magazines.map(item =>
            ({ value: valueToStr(item.slug), text: item.name })
        )
    ]

    let filter = currentMagazine ? currentMagazine.slug : null

    return (
        <Select
            options={options}
            //placeholder='Magazine'
            value={valueToStr(filter)}
            onChange={(event: any, data: DropdownProps) => manualSelectMagazineSlug(strToValue(data.value as string))} />
    )
}