import React, { useContext } from 'react'
import { Grid, Loader, Container } from 'semantic-ui-react'
import PdfFilesTable from './PdfFilesTable'
import { useObservable } from '../Common'
import { RootStoreContext } from '../../app/stores/rootStore'
import { FeatureToggleDto, LandingPdfFileRow } from './models'
import WeekFilter from './WeekFilter'
import TypeFilter from './TypeFilter'
import { MagazineType } from '../../app/models/models'
import MagazineFilter from './MagazineFilter'
import YearFilter from './YearFilter'

export default () => {

    let { landingStore, commonStore } = useContext(RootStoreContext)
    let {
        items$,
        yearFilter$, setYearFilter,
        weekFilter$, setWeekFilter,
        typeFilter$, setTypeFilter,
    } = landingStore

    let yearFilter: number | null = useObservable(yearFilter$, null)
    let weekFilter: number | null = useObservable(weekFilter$, null)
    let typeFilter: MagazineType | null = useObservable(typeFilter$, null)
    let items: LandingPdfFileRow[] | null = useObservable(items$, null)

    let featureToggle: FeatureToggleDto | null = useObservable(commonStore.featureToggle$, null)

    return (
        items === null
            ?
            <Loader active={true} content={'Loading...'} />
            :
            <Grid stackable id='landing'>
                <Grid.Column>
                    <Container fluid>
                        <div
                            style={{
                                display: 'flex', justifyContent: 'space-between',
                                alignItems: 'baseline',
                                flexWrap: 'wrap',
                            }}>
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
                                    gridGap: '1rem',
                                    flex: '1 0 auto',
                                    marginBottom: '1rem',
                                }}>
                                <MagazineFilter />
                                <YearFilter filter={yearFilter}
                                    setFilter={setYearFilter}
                                    placeholder='Year' />
                                <WeekFilter filter={weekFilter}
                                    setFilter={setWeekFilter}
                                    placeholder='Week' />
                                <TypeFilter filter={typeFilter}
                                    setFilter={setTypeFilter}
                                    placeholder='Type' />
                                {/*
                            <Button
                                icon
                                size='small'
                                onClick={() => forceReload()}>
                                <Icon name='refresh'></Icon>
                            </Button>
                            */}
                            </div>
                        </div>
                    </Container>
                    <PdfFilesTable />
                </Grid.Column>
            </Grid>
    )
}
