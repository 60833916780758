import React, { useContext } from 'react';
import { Form as FinalForm, Field } from 'react-final-form';
import { Form, Button, Header } from 'semantic-ui-react';
import TextInput from '../../app/common/form/TextInput';
import { RootStoreContext } from '../../app/stores/rootStore';
import { IResetPasswordModel } from './models';
import { FORM_ERROR } from 'final-form';
import { combineValidators, isRequired } from 'revalidate';
import ErrorMessage from '../../app/common/form/ErrorMessage';
import LoginContainer from './LoginContainer';

const validate = combineValidators({
	password: isRequired('Password'),
	confirmPassword: isRequired('Confirm password')
});

const ResetPasswordForm = () => {
	const rootStore = useContext(RootStoreContext);
	const { resetPassword } = rootStore.userStore;

	return (
		<LoginContainer innerForm={
			<FinalForm
				onSubmit={(values: IResetPasswordModel) => {

					let params: any = new URL(window.location.href).searchParams
					values.code = params.get('token')
					values.userId = params.get('userId')

					return resetPassword(values).catch(error => ({
						[FORM_ERROR]: error
					}))
				}}
				validate={validate}
				render={({
					handleSubmit,
					submitting,
					submitError,
					invalid,
					dirtySinceLastSubmit
				}) => (
						<Form onSubmit={handleSubmit} error>
							<Header
								as='h2'
								content='Reset Password'
								color='teal'
								textAlign='center' />
							<Field
								name='password'
								component={TextInput}
								placeholder='Password'
								type='password' />

							<Field
								name='confirmPassword'
								component={TextInput}
								placeholder='Confirm password'
								type='password' />
							{submitError && !dirtySinceLastSubmit && (
								<ErrorMessage
									error={submitError}
									text='Invalid password or confirmation password' />
							)}

							<Button className="login-button"
								disabled={invalid}
								loading={submitting}
								color='teal'
								content='Reset password'
								fluid />
						</Form>
					)} />}
		/>
	);
};

export default ResetPasswordForm;
