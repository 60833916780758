import { LogLevel, Navigation } from "../../app/models/models"
import { IPagedParams, ISortedParams, IFilteredParams, PageRequest } from "../../app/models/request-response-base"

export interface PdfFile {
    id: string
    status: PdfFileStatus
    lastModified: string
    lastErrorMessage: string

    remoteFileName: number
    publicName: string
    name: string
    magazineCode: string
    datestamp: string
    year: number
    week: number
    isAttachment: boolean | null
    isChronicles: boolean | null
    fileSize: number

    logs: LogDto[]
    navigations: Navigation[]
}

export interface PdfFileRow {
    id: string
    status: PdfFileStatus
    lastModified: string
    lastErrorMessage: string

    remoteFileName: number
    publicName: string
    name: string
    magazineCode: string
    datestamp: string
    year: number
    week: number
    isAttachment: boolean | null
    isChronicles: boolean | null
    fileSize: number

    magazineName: string
}

export enum PdfFileStatus {
    New = 1,

    ToMove = 10,
    Moving = 11,
    MoveError = 12,
    Moved = 13,

    ToDownload = 20,
    Downloading = 21,
    DownloadError = 22,
    Downloaded = 23,

    ToProcess = 30,
    Processing = 31,
    ProcessError = 32,
    Processed = 33,
}

export const PdfFileStatuses = {
    MoveAvailable: [
        PdfFileStatus.New,
    ],
    MoveRetryAvailable: [
        PdfFileStatus.MoveError,
    ],
    DownloadAvailable: [
        PdfFileStatus.Moved,
    ],
    DownloadRetryAvailable: [
        PdfFileStatus.DownloadError,
        PdfFileStatus.Downloaded,
        PdfFileStatus.ProcessError,
        PdfFileStatus.Processed,
    ],
    ProcessAvailable: [
        PdfFileStatus.Downloaded,
    ],
    ProcessRetryAvailable: [
        PdfFileStatus.ProcessError,
        PdfFileStatus.Processed,
    ],
}

export interface LogDto {
    id: string
    timeStamp: string
    message: string
    logLevel: LogLevel
    status: PdfFileStatus
    user: string
}

export interface PdfFilesParams extends IPagedParams, ISortedParams, IFilteredParams {
    searchFilter: string | null
    statusFilter: PdfFileStatus | null
}

export interface PdfFileRowsRequest extends PageRequest {
    searchFilter: string | null
    statusFilter: PdfFileStatus | null
}

export enum PdfFileRowField {
    Title = 'Title',
    AssetExtId = 'AssetExtId',
    AssetIntId = 'AssetIntId',
    AssetType = 'AssetType',
    ModifiedDate = 'ModifiedDate',
    Priority = 'Priority',
    Status = 'Status',
}