import React from 'react'
import { Table } from 'semantic-ui-react'
import { ModifiedDate, UserName, PdfFileStatusLabel, LogLevelLabel } from '../Common'
import { PdfFile } from './models';

export default (props: {
    pdfFile: PdfFile,
}) => {
    let { pdfFile } = props

    let logs = pdfFile.logs
    logs.sort((a, b) => -a.timeStamp.localeCompare(b.timeStamp))

    return (
        <>
            {
                pdfFile.logs.length > 0 &&
                <Table celled size='small'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width='2'>Level</Table.HeaderCell>
                            <Table.HeaderCell width='2'>Date</Table.HeaderCell>
                            <Table.HeaderCell width='6'>Message</Table.HeaderCell>
                            <Table.HeaderCell width='2'>User</Table.HeaderCell>
                            <Table.HeaderCell width='2'>Status</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            logs
                                .map((log) =>
                                    (<Table.Row key={log.id}>
                                        <Table.Cell><LogLevelLabel logLevel={log.logLevel} /></Table.Cell>
                                        <Table.Cell><ModifiedDate date={log.timeStamp} /></Table.Cell>
                                        <Table.Cell>{log.message}</Table.Cell>
                                        <Table.Cell><UserName user={log.user} /></Table.Cell>
                                        <Table.Cell><PdfFileStatusLabel status={log.status} /></Table.Cell>
                                    </Table.Row>))
                        }
                    </Table.Body>
                </Table>
            }
        </>
    )
}
