import React from 'react';
import { Segment, Button, Header, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Routes from '../../app/layout/Routes';

export default () => {

    return (
        <Segment placeholder>
            <Header icon>
                <Icon name='thumbs up' />
                You have successfully registered. Please go to email and follow the link to complete registration.
        </Header>
            <Segment.Inline>
                <Button as={Link} to={Routes.Login()} primary>
                    Go to Login page
            </Button>
            </Segment.Inline>
        </Segment>
    )
}