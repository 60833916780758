import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Label, SemanticCOLORS, Popup, Accordion, Icon, Pagination, Menu, Input, InputOnChangeData, LabelProps, Button } from 'semantic-ui-react'
import { toast } from 'react-toastify'
import { Observable, timer, of, Subject } from 'rxjs'
import { tap, exhaustMap, catchError, take, map, debounceTime, switchMap, filter } from 'rxjs/operators'
import { RootStoreContext, RootStore } from '../app/stores/rootStore'
import { PrepareForDeployStatus } from '../app/models/jobs'
import { Priority, UpdatePriorityRequest, UpdateStatusRequest, LogLevel, ProviderMode, Navigation, NavigationEntityType } from '../app/models/models'
import { IPagedStore } from '../app/stores/pagedStoreBase'
import agent from '../app/api/agent'
import Routes from '../app/layout/Routes'
import { Link, useParams, useHistory } from 'react-router-dom'
import { PdfFileStatus } from './pdf-files/models'

export function ProviderModeLabel(props: { mode: ProviderMode, oneLetter?: boolean }) {

    let color: SemanticCOLORS =
        props.mode === ProviderMode.Disabled
            ? 'grey'
            : props.mode === ProviderMode.Manual
                ? 'blue'
                : 'green'

    let title = props.oneLetter
        ? ProviderMode[props.mode][0]
        : ProviderMode[props.mode]

    return (
        <Label color={color} basic size='mini' >
            {title}
        </Label>
    )
}

export function GetStatusColor(statusStr: string, isDone: boolean): SemanticCOLORS {
    switch (true) {
        case isDone: return 'green'
        case statusStr.startsWith('To'): return 'grey'
        case statusStr.endsWith('ing'): return 'yellow'
        case statusStr.endsWith('Error'): return 'red'
        case statusStr.endsWith('ed'): return 'blue'
        default: return 'blue'
    }
}

export function StatusLabel<T>(enumType: any, doneStatus: T) {
    return (props: { status?: T, error?: string, suffix?: string, } & LabelProps) => {
        let { status, error, suffix } = props
        if (!status) {
            return null
        }
        let statusStr = enumType[status]
        let isDone = status === doneStatus
        let color: SemanticCOLORS = GetStatusColor(statusStr, isDone)
        let displayStatus = isDone ? 'Done' : statusStr
        let title = `${displayStatus}${suffix || ''}`

        let handleClick = error
            ? () => {
                navigator.clipboard.writeText(error || '')
                toast.success('Copied!', { autoClose: 1500 })
            }
            : () => { }

        let userError = error
        userError = userError && userError.indexOf('Exception: ') ? userError.split('Exception: ')[1] : userError
        userError = userError && userError.indexOf('   at ') ? userError.split('   at ')[0] : userError

        let maxErrorLength = 300
        let trimmedError = userError && userError.length > maxErrorLength
            ? userError.substring(0, maxErrorLength) + '...'
            : userError

        return error && statusStr.endsWith('Error')
            ? <Popup content={trimmedError} position='bottom center'
                trigger={
                    <Label
                        onClick={handleClick}
                        color={color} horizontal {...props}>{title}</Label>
                } />
            : <Label color={color} horizontal {...props}>{title}</Label>
    }
}

export const PdfFileStatusLabel = StatusLabel(PdfFileStatus, PdfFileStatus.Processed)

export function LogsAccordion(props: { logs?: string, title: string }) {
    let [accOpen, setAccOpen] = useState<boolean>(false)

    return props.logs
        ? <Accordion fluid styled style={{ marginBottom: '10px' }}>
            <Accordion.Title
                active={accOpen}
                index={0}
                onClick={() => setAccOpen(!accOpen)}>
                <Icon name='dropdown' />
                {props.title}
            </Accordion.Title>
            <Accordion.Content active={accOpen}>
                <p style={{ whiteSpace: 'pre-wrap' }}>
                    {props.logs}
                </p>
            </Accordion.Content>
        </Accordion>
        : null
}

export function VSpace(props: { height: string | number }) {

    return <div style={{ height: props.height }}></div>
}

export function InfoBox(props: { children: any }) {
    let { children } = props

    return <div style={{ margin: '-5px -5px -10px' }}>
        {children}
    </div>
}

export function InfoLabel(props: { children: any, description?: string }) {
    let { children, description } = props

    let handleClick = typeof children === 'string'
        ? () => {
            navigator.clipboard.writeText(children)
            toast.success('Copied!', { autoClose: 1500 })
        }
        : () => { }

    return <Label size='large' basic onClick={handleClick}
        style={{ cursor: 'pointer', marginBottom: '5px' }}>
        {
            description && `${description}: `
        }
        {children}
    </Label>
}


export function useObservable<T>(
    observable$: Observable<T>,
    initialValue: T,
    effect: null | ((value: T) => void) = null,
): T {
    const [value, update] = useState<T>(initialValue)

    useEffect(() => {
        const s = observable$
            .pipe(
                tap(effect || (() => {
                })),
            )
            .subscribe(update)

        return () => s.unsubscribe()
    }, [observable$, effect])

    return value
}

export function refreshWithTimer<T>(request: () => Observable<T>, intervalSec: number) {
    return timer(0, intervalSec * 1000)
        .pipe(
            filter(() => !document.hidden),
            exhaustMap(() => request()),
        )
}

export function SimplePaginator(props: {
    store: IPagedStore
}) {
    let { store: { pageIndex$, totalCount$, pageSize, setPageIndex } } = props
    let pageIndex: number = useObservable(pageIndex$, 1)
    let totalCount: number = useObservable(totalCount$, 0)
    let totalPages: number = Math.ceil(totalCount / pageSize)

    return (
        <>
            {
                totalPages > 1 &&
                <Menu pagination size='small'>
                    <Pagination
                        boundaryRange={0}
                        defaultActivePage={pageIndex}
                        ellipsisItem={null}
                        siblingRange={2}
                        size='mini'
                        onPageChange={(event, data) => setPageIndex(Number(data.activePage))}
                        totalPages={totalPages} />
                </Menu>
            }
        </>
    )
}

export function Paginator(props: {
    store: IPagedStore
}) {
    let { store: { pageIndex$, totalCount$, pageSize, setPageIndex } } = props
    let pageIndex: number = useObservable(pageIndex$, 1)
    let totalCount: number = useObservable(totalCount$, 0)
    let totalPages: number = Math.ceil(totalCount / pageSize)

    return (
        <>
            <b>{`Total items: ${totalCount}`}</b>
            {
                totalPages > 1 &&
                <Menu floated='right' pagination size='small'>
                    <Pagination
                        boundaryRange={0}
                        defaultActivePage={pageIndex}
                        ellipsisItem={null}
                        siblingRange={2}
                        size='mini'
                        onPageChange={(event, data) => setPageIndex(Number(data.activePage))}
                        totalPages={totalPages} />
                </Menu>
            }
        </>
    )
}

export function PrepareForDeployBox() {

    let rootStore = useContext(RootStoreContext)
    let { isLoggedIn } = rootStore.userStore
    let [appStartDate, setAppStartDate] = useState<string | null>(null)
    let [timeToReload$, setTimeToReload$] = useState<Observable<number> | null>(null)

    let countdownSeconds = 5

    let prepareForDeployStatus$ = useMemo(() => timer(0, 60 * 1000)
        .pipe(
            filter(() => !document.hidden),
            exhaustMap(() => isLoggedIn //&& false
                ? agent.Jobs.statusPrepareForDeploy().pipe(catchError(() => of(null)))
                : of(null)),
            tap(prepareForDeployStatus => {
                if (prepareForDeployStatus != null && prepareForDeployStatus.appStartDate !== appStartDate) {
                    if (appStartDate !== null) {
                        setTimeToReload$(timer(0, 1000).pipe(take(countdownSeconds + 1), map(x => countdownSeconds - x)))
                    }
                    setAppStartDate(prepareForDeployStatus.appStartDate)
                }
            }),
        ), [isLoggedIn, appStartDate, countdownSeconds])

    /*let prepareForDeployStatus = */useObservable<PrepareForDeployStatus | null>(prepareForDeployStatus$, null)

    let timeToReload = useObservable<number | null>(timeToReload$ || of(null), null)


    if (timeToReload !== null && timeToReload === 0) {
        window.location.reload()
    }
    return null
    /*
    if (timeToReload !== null) {
        if (timeToReload === 0) {
            window.location.reload()
        }

        return null
        return <Label color='green'
            onClick={() => setTimeToReload$(of(0))}
            style={{
                width: '100%', display: 'block', margin: '-20px -15px 20px',
                boxSizing: 'content-box', padding: '.5833em 15px', borderRadius: '0',
                textAlign: 'center'
            }}>
            {
                timeToReload === 0
                    ? 'Reloading...'
                    : `New version is deployed. Page will be reloaded in ${timeToReload} seconds. Click to reload now...`
            }
        </Label>
    }

    return (
        prepareForDeployStatus && prepareForDeployStatus.isPreparing
            ? <Label color='red' style={{
                width: '100%', display: 'block', margin: '-20px -15px 20px',
                boxSizing: 'content-box', padding: '.5833em 15px', borderRadius: '0',
                textAlign: 'center'
            }}>
                {
                    prepareForDeployStatus.isReady
                        ? 'Ready to deploy. All jobs are finished. New jobs will not be run'
                        : `Preparing for deploy. New jobs will not be run. Waiting for ${prepareForDeployStatus.processingCount} jobs to finish...`
                }
            </Label>
            : null
    )
    */
}

export function SearchInput(props: {
    searchFilter: string | null,
    setSearchFilter: (searchFilter: string | null) => void,
    placeholder: string,
}) {
    let { searchFilter, setSearchFilter, placeholder } = props

    return (
        <Input
            type='search'
            style={{ minWidth: '300px', marginRight: '10px' }}
            icon={searchFilter
                ? <Icon name='close' link onClick={() => setSearchFilter(null)} />
                : <Icon name='search' />}
            placeholder={placeholder}
            value={searchFilter || ''}
            onChange={(event: any, data: InputOnChangeData) => setSearchFilter(data.value)} />
    )
}

export function ModifiedDate(props: { date: string }) {

    let { date } = props

    let dateVal = new Date(date)
    let isToday = dateVal.toLocaleDateString() === new Date().toLocaleDateString()
    var result = isToday
        ? dateVal.toLocaleTimeString()
        : dateVal.toLocaleDateString()

    return (
        <span title={dateVal.toLocaleString()}>
            {result}
        </span>
    )
}

export function UserName(props: { user: string }) {

    let { user } = props

    var parts = user.split('@')
    var displayValue = parts.length === 1
        ? user
        : parts[0]

    return (
        <span title={user}>
            {displayValue}
        </span>
    )
}

export function getPriorityColor(priority: Priority): SemanticCOLORS {
    switch (priority) {
        case Priority.Skip:
            return 'grey'
        case Priority.Low:
            return 'yellow'
        case Priority.Medium:
            return 'green'
        case Priority.High:
            return 'red'
    }
}

export function getPriorityNextValue(priority: Priority): Priority {
    switch (priority) {
        case Priority.Skip:
            return Priority.Low
        case Priority.Low:
            return Priority.Medium
        case Priority.Medium:
            return Priority.High
        case Priority.High:
            return Priority.Skip
    }
}

export function PriorityEditor(updatePriority: (request: UpdatePriorityRequest) => Observable<boolean>) {
    return (props: {
        item: {
            id: string
            priority: Priority
        },
    }) => {

        // hack to avoid warnings
        let use_State = useState
        let use_Effect = useEffect

        let { item } = props
        let [priorityValuesByClicks$] = use_State(new Subject<Priority>())
        let [currentPriority, setCurrentPriority] = use_State(item.priority)
        let [isUpdating, setIsUpdating] = use_State(false)
        let [lastSavedPriority, setLastSavedPriority] = use_State(item.priority)

        use_Effect(() => {
            !isUpdating && setCurrentPriority(item.priority)
        }, [item.priority])

        use_Effect(() => {
            let sub = priorityValuesByClicks$
                .pipe(
                    debounceTime(500),
                    tap(() => setIsUpdating(true)),
                    switchMap(priorityToSave =>
                        updatePriority({
                            id: item.id,
                            priority: priorityToSave,
                        })
                            .pipe(
                                catchError(() => of(false)),
                                map(isSuccess => isSuccess ? priorityToSave : null)
                            )
                    ),
                    tap(() => setIsUpdating(false)),
                )
                .subscribe((savedPriority: Priority | null) => {
                    if (savedPriority === null) {
                        setCurrentPriority(lastSavedPriority)
                        item.priority = lastSavedPriority
                        toast.error('Error occurred on saving priority')
                    }
                    else {
                        setLastSavedPriority(savedPriority)
                        toast.success('Priority has been saved successfully')
                    }
                })
            return () => {
                sub.unsubscribe()
            }
        }, [lastSavedPriority, item.id, item.priority, priorityValuesByClicks$])

        let color = getPriorityColor(currentPriority)
        let name = Priority[currentPriority]

        let handleClick = (event: any) => {
            event.stopPropagation()
            let nextPriority = getPriorityNextValue(currentPriority)
            setCurrentPriority(nextPriority)
            priorityValuesByClicks$.next(nextPriority)
        }

        return <Label color={color} size='mini'
            style={{ userSelect: 'none', cursor: 'pointer' }}
            onClick={event => handleClick(event)}
            title={`${name} priority. Click to change`}>
            {name}
        </Label>
    }
}

export const PdfFilePriorityEditor = PriorityEditor(agent.PdfFiles.updatePriority)

export interface StatusesCount<T> {
    status: T
    count: number
}

export function StatusButtonsFilter<T>(enumType: any,
    statusLabel: (props: { status?: T, error?: string, suffix?: string, } & LabelProps) => JSX.Element | null) {
    return (props: {
        store: {
            statusFilter$: Observable<T | null>,
            setStatusFilter: (status: T | null) => void,
            countByStatus$: Observable<StatusesCount<T>[]>,
        },
    }) => {

        // hack to avoid warnings
        let use_Observable = useObservable

        let { store } = props
        let {
            statusFilter$, setStatusFilter,
            countByStatus$,
        } = store

        let statusFilter = use_Observable<T | null>(
            statusFilter$, null
        )

        let countByStatus = use_Observable<StatusesCount<T>[] | null>(
            countByStatus$, null
        )

        let getCount = (status: T): number | null => {
            if (!countByStatus) {
                return null
            }

            let statusCount = countByStatus && countByStatus.find(x => x.status === status)

            return (statusCount && statusCount.count) || 0
        }

        let handleStatusClick = (status: T) => {
            setStatusFilter(status === statusFilter ? null : status)
        }

        return (
            <div style={{ marginBottom: '-5px' }}>
                {
                    Object.keys(enumType)
                        .map(key => enumType[key])
                        .filter(status => !isNaN(Number(status)))
                        .map(status => {

                            let count = getCount(status)

                            return ((count !== null && count > 0) || status === statusFilter) &&
                                statusLabel({
                                    key: status,
                                    style: { marginBottom: '5px' },
                                    status: status,
                                    suffix: ` ${count === null ? '' : count}`,
                                    onClick: () => handleStatusClick(status),
                                    basic: status === statusFilter,
                                    as: 'a'
                                })
                        })
                }
            </div>
        )
    }
}

export const PdfFileStatusButtonsFilter = StatusButtonsFilter<PdfFileStatus>(PdfFileStatus, PdfFileStatusLabel)


export function FlowButton<T>(init: {
    title: string,
    statusTo: T,
    availableIn: T[],
    primaryIn?: T[],
    updateStatusGetter: (x: RootStore) => (request: UpdateStatusRequest<T>) => Promise<void>,
}) {
    let {
        title,
        statusTo: targetStatus,
        availableIn,
        primaryIn,
        updateStatusGetter,
    } = init

    return (props: {
        id: string,
        status: T,
        updating: boolean
    }) => {

        let { id, status, updating } = props

        // hack to avoid warnings
        let use_Context = useContext
        const rootStore = use_Context(RootStoreContext)
        const updateStatus = updateStatusGetter(rootStore)

        let isAvailable = availableIn.includes(status)
        let isPrimary = !primaryIn || primaryIn.includes(status)

        return (
            <>
                {
                    isAvailable &&
                    <Button size='mini'
                        disabled={updating}
                        loading={updating}
                        className={isPrimary ? 'primary' : ''}
                        onClick={() => updateStatus({ id: id, status: targetStatus })}>
                        {title}
                    </Button>
                }
            </>
        )
    }
}

export const LogLevelLabel = (props: { logLevel: LogLevel }) => {
    let { logLevel } = props
    let logLevelStr = LogLevel[logLevel]
    let color: SemanticCOLORS = logLevel === LogLevel.Error
        ? 'red'
        : 'blue'

    return <Label color={color} basic horizontal>{logLevelStr}</Label>
}

export function GetNavigationTitle(entityType: NavigationEntityType, entityTitle: string): string {
    let typeTitle = NavigationEntityType[entityType]
    switch (entityType) {
        case NavigationEntityType.PdfFile:
            return typeTitle
        default: return `${typeTitle} ${entityTitle}`
    }
}

export function GetNavigationLink(entityType: NavigationEntityType, id: string): string {
    switch (entityType) {
        case NavigationEntityType.PdfFile:
            return Routes.PdfFile(id)
    }
}

export const NavigationButtons = (props: { entityType: NavigationEntityType, entity: { id: string, navigations: Navigation[] } }) => {
    let { entityType, entity } = props
    let { id, navigations } = entity

    let link = `${window.location.origin}${GetNavigationLink(entityType, id)}`
    let handleCopyLink = () => {
        navigator.clipboard.writeText(link)
        toast.success('Copied!', { autoClose: 1500 })
    }

    return <>
        <Button size='mini'
            primary
            onClick={() => handleCopyLink()}>
            Copy link
        </Button>
        {
            navigations
                .map(x =>
                    <Link
                        key={x.entityId}
                        to={GetNavigationLink(x.entityType, x.entityId)}
                        style={{ marginLeft: '1rem' }}
                    >
                        {GetNavigationTitle(x.entityType, x.entityTitle)}
                        {' '}
                        <Icon name='arrow alternate circle right outline' />
                    </Link>
                )
        }
    </>
}

export function useParamsListId(store: {
    clearFilters: () => void,
    setSearchFilter: (searchFilter: string) => void,
}, listRoute: string) {
    const { id } = useParams<{ id: string }>();

    let history = useHistory();
    if (id) {
        store.clearFilters();
        store.setSearchFilter(id)
        history.replace(listRoute);
    }
}