import React from 'react'
import { Tab, Segment } from 'semantic-ui-react';
import { NavigationEntityType } from '../../app/models/models';
import { PdfFile } from './models';
import { InfoLabel, InfoBox, NavigationButtons } from '../Common';
import PdfFileLogsView from './PdfFileLogsView';

export default (props: {
    pdfFile: PdfFile,
}) => {
    let { pdfFile } = props

    return (
        <>
            <Segment>
                <InfoBox>
                    <InfoLabel>{pdfFile.remoteFileName}</InfoLabel>
                    <InfoLabel description='File size'>{pdfFile.fileSize}</InfoLabel>
                    <InfoLabel description='Year'>{pdfFile.year}</InfoLabel>
                    <InfoLabel description='Week'>{pdfFile.week}</InfoLabel>
                    <InfoLabel description='Datestamp'>{pdfFile.datestamp}</InfoLabel>
                    <InfoLabel description='Is attachment'>{
                        pdfFile.isAttachment === true
                            ? 'Yes'
                            : pdfFile.isAttachment === false
                                ? 'No'
                                : '---'
                    }</InfoLabel>
                    <InfoLabel description='Is chronicles'>{
                        pdfFile.isChronicles === true
                            ? 'Yes'
                            : pdfFile.isChronicles === false
                                ? 'No'
                                : '---'
                    }</InfoLabel>
                    <InfoLabel description='Id'>{pdfFile.id}</InfoLabel>
                </InfoBox>
            </Segment>
            <div style={{ marginBottom: '1rem' }}>
                <NavigationButtons entityType={NavigationEntityType.PdfFile} entity={pdfFile} />
            </div>
            <Tab panes={[
                { menuItem: 'Logs', render: () => <Tab.Pane><PdfFileLogsView pdfFile={pdfFile} /></Tab.Pane> },
            ]} />
        </>
    )
}
