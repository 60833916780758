import React, { useContext } from 'react'
import { Grid, Message, Button, Header } from 'semantic-ui-react'
import { useObservable } from '../Common'
import { RootStoreContext } from '../../app/stores/rootStore'
import { WidgetMagazineRow, PdfFileType } from './models'
import Magazines from './Magazines'
import agent from '../../app/api/agent'
import { toast } from 'react-toastify'

function fallbackCopyTextToClipboard(text: string) {
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
        console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}
function copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(function () {
        console.log('Async: Copying to clipboard was successful!');
    }, function (err) {
        console.error('Async: Could not copy text: ', err);
    });
}

export default () => {

    let { widgetsStore } = useContext(RootStoreContext)
    let {
        currentRow$,
    } = widgetsStore

    let currentRow: WidgetMagazineRow | null = useObservable(currentRow$, null)
    let widgetNormal = currentRow
        ? `<iframe width="240" height="450" src="${agent.Links.magazineWidgetAbsoluteUrl(currentRow.slug, PdfFileType.Normal)}" frameborder="0"></iframe>`
        : null

    let widgetAttachment = currentRow
        ? `<iframe width="240" height="450" src="${agent.Links.magazineWidgetAbsoluteUrl(currentRow.slug, PdfFileType.Attachment)}" frameborder="0"></iframe>`
        : null

    let widgetChronicles = currentRow
        ? `<iframe width="240" height="450" src="${agent.Links.magazineWidgetAbsoluteUrl(currentRow.slug, PdfFileType.Chronicles)}" frameborder="0"></iframe>`
        : null

    let copyWidget = (widget: string | null) => {
        copyTextToClipboard(widget || '')
        toast.success('Copied!', { autoClose: 1500 })
    }

    return (
        <Grid stackable>
            <Grid.Column width={4}>
                <Magazines />
            </Grid.Column>
            <Grid.Column width={4}>
                {
                    widgetNormal &&
                    <>
                        <Header>Magazine widget</Header>
                        <div dangerouslySetInnerHTML={{ __html: widgetNormal }} />
                        <Header>Code</Header>
                        <Message>
                            <p style={{ wordBreak: 'break-word' }}>{widgetNormal}</p>
                        </Message>
                        <Button primary onClick={() => copyWidget(widgetNormal)}>Copy HTML code</Button>
                    </>
                }
            </Grid.Column>
            <Grid.Column width={4}>
                {
                    widgetAttachment &&
                    <>
                        <Header>Attachment widget</Header>
                        <div dangerouslySetInnerHTML={{ __html: widgetAttachment }} />
                        <Header>Code</Header>
                        <Message>
                            <p style={{ wordBreak: 'break-word' }}>{widgetAttachment}</p>
                        </Message>
                        <Button primary onClick={() => copyWidget(widgetAttachment)}>Copy HTML code</Button>
                    </>
                }
            </Grid.Column>
            <Grid.Column width={4}>
                {
                    widgetChronicles &&
                    <>
                        <Header>Chronicles widget</Header>
                        <div dangerouslySetInnerHTML={{ __html: widgetChronicles }} />
                        <Header>Code</Header>
                        <Message>
                            <p style={{ wordBreak: 'break-word' }}>{widgetChronicles}</p>
                        </Message>
                        <Button primary onClick={() => copyWidget(widgetChronicles)}>Copy HTML code</Button>
                    </>
                }
            </Grid.Column>
        </Grid>
    )
}
