import agent from '../../app/api/agent'
import { RootStore } from '../../app/stores/rootStore'
import { combineLatest, Observable, BehaviorSubject, of, Subject } from 'rxjs'
import { tap, switchMap, distinctUntilChanged, startWith, map } from 'rxjs/operators'
import { WidgetMagazineRow } from './models'

export default class WidgetsStore {

    constructor(private rootStore: RootStore) {
    }

    private isMagazinesLoadingSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    isMagazinesLoading$: Observable<boolean> = this.isMagazinesLoadingSubject$.asObservable()
    magazines$ = combineLatest(
        of(true),
    )
        .pipe(
            tap(() => this.isMagazinesLoadingSubject$.next(true)),
            switchMap(() => agent.Public.magazines({
                skip: 0,
                take: 1000,
                sortField: '',
                sortAsc: true,
                filters: [],
            })),
            tap(() => this.isMagazinesLoadingSubject$.next(false)),
        )

    private manualSelectedRowSubject$: Subject<WidgetMagazineRow | null> = new Subject<WidgetMagazineRow | null>()
    manualSelectedRow$: Observable<WidgetMagazineRow | null> = this.manualSelectedRowSubject$.asObservable()

    manualSelectRow = (row: WidgetMagazineRow) => {
        this.manualSelectedRowSubject$.next(row)
    }

    currentRow$: Observable<WidgetMagazineRow | null> =
        combineLatest(
            this.manualSelectedRowSubject$.pipe(startWith(null), distinctUntilChanged()),
            this.magazines$.pipe(distinctUntilChanged()),
        )
            .pipe(
                map(([selectedRow, items]): WidgetMagazineRow => {
                    let updatedSelectedRow = selectedRow
                        && items.find(x => x.id === selectedRow.id)

                    return updatedSelectedRow || items[0] || null
                }),
                distinctUntilChanged((x, y) => x && y && x.id === y.id),
            )
}