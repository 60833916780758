import React, { useContext } from 'react'
import { Loader, Table, Icon, Container, Button } from 'semantic-ui-react'
import { RootStoreContext } from '../../../app/stores/rootStore'
import { ProviderRow } from './models'
import { useHistory, Link } from 'react-router-dom'
import Routes from '../../../app/layout/Routes'
import { ProviderModeLabel, Paginator, useObservable } from '../../Common'

export default () => {

    let rootStore = useContext(RootStoreContext)
    let { providersStore } = rootStore
    let {
        items$, isPageLoading$,
        remove,
        add, isAdding$,
    } = providersStore
    let { openModal } = rootStore.confirmModalStore

    let items: ProviderRow[] = useObservable(items$, [])
    let isPageLoading: boolean = useObservable(isPageLoading$, true)

    let isAdding: boolean = useObservable(isAdding$, true)

    let history = useHistory()
    const onAddClick = () => {
        add().then((newProviderId) => {
            if (newProviderId) {
                history.push(Routes.ProviderEdit(newProviderId))
            }
        })
    }

    const handleOnDeleteClick = (id: string, name: string) => {
        const removeProvider = () => {
            remove(id)
        }
        openModal('Delete confirm', `Are you sure you want to delete "${name || ''}" provider?`, removeProvider)
    }

    return (
        <Container fluid>
            <Loader active={isPageLoading} />
            <Table celled singleLine striped selectable sortable size='small'>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={3} >Name</Table.HeaderCell>
                        <Table.HeaderCell width={2}>Smooths profile</Table.HeaderCell>
                        <Table.HeaderCell width={1} textAlign={'center'}>Mode</Table.HeaderCell>
                        <Table.HeaderCell width={2} textAlign={'center'}>
                            <Button
                                loading={isAdding}
                                primary
                                onClick={onAddClick}>
                                Add provider
                            </Button>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {items.map((item) =>
                        <Table.Row key={item.id} className="smooth-view-row">
                            <Table.Cell>{item.name}</Table.Cell>
                            <Table.Cell>{item.smoothsEncodingProfile}</Table.Cell>
                            <Table.Cell textAlign={'center'}>
                                <ProviderModeLabel mode={item.mode} />
                            </Table.Cell>
                            <Table.Cell textAlign={'center'}>
                                <div>
                                    <Link to={Routes.ProviderEdit(item.id)}
                                        title={'Edit'}>
                                        <Icon size="large" name='edit outline' />
                                    </Link>
                                    <Link to={Routes.Providers()}
                                        title={'Delete'}
                                        onClick={(event) => handleOnDeleteClick(item.id, item.name)}>
                                        <Icon size="large" name='delete' />
                                    </Link>
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            <Paginator store={providersStore} />
        </Container>
    )
}