import React, { useContext } from 'react'
import { RouteProps, RouteComponentProps, Route, Redirect } from 'react-router-dom';
import { RootStoreContext } from '../stores/rootStore';
import { observer } from 'mobx-react-lite';
import Routes from './Routes';

interface IProps extends RouteProps {
    component?: React.ComponentType<RouteComponentProps<any>>,
    isAdminCan?: boolean,
    isUserCan?: boolean,
    isRegisteredCan?: boolean
}

const PrivateRoute: React.FC<IProps> = ({ component: Component, isAdminCan = false, isUserCan = false, isRegisteredCan = false, ...rest }) => {
    const rootStore = useContext(RootStoreContext);
    const { isLoggedIn, isAdmin, isUser, isRegistered } = rootStore.userStore;

    // PrivateRoute only for registered users
    if (!isLoggedIn) {
        return (<Route render={() => <Redirect to={Routes.Login()} />} />);
    }

    // is user can reach specified route
    const isAccessAllowed = (isAdminCan && isAdmin) ||
        (isUserCan && isUser) ||
        (isRegisteredCan && isRegistered);

    // for group routes element
    if (Component === undefined) {
        return isAccessAllowed ?
            <Route render={() => rest.children} /> : // Return nested routes
            <Route render={() => <Redirect to={Routes.Forbidden()} />} />; //otherwise go to undefined page  
    }

    // for single route elements
    return (
        <Route
            {...rest}
            render={(props) => isAccessAllowed ? <Component {...props} /> : <Redirect to={Routes.Forbidden()} />}
        />
    )
}

export default observer(PrivateRoute)
