import React, { useContext, useEffect } from 'react'
import { Grid, Form, Button, Header, Loader } from 'semantic-ui-react'
import { RouteComponentProps } from 'react-router'
import { Form as FinalForm, Field } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { FORM_ERROR } from 'final-form';

import { RootStoreContext } from '../../../app/stores/rootStore';
import CheckboxInput from '../../../app/common/form/CheckboxInput';
import ErrorMessage from '../../../app/common/form/ErrorMessage';
import { IUserUpdateDtoModel } from '../../user/models';
import TextInput from '../../../app/common/form/TextInput';
import Routes from '../../../app/layout/Routes';

interface UserDetailsParams {
    id: string;
}

const UserEditForm: React.FC<RouteComponentProps<UserDetailsParams>> = ({
    match,
    history
}) => {

    const rootStore = useContext(RootStoreContext);
    const { editUser, loadUserDetails, userForEdit, loadingInitial } = rootStore.userStore;

    useEffect(() => {
        loadUserDetails(match.params.id);
    }, [loadUserDetails, match.params.id, history]);

    if (!userForEdit) {
        return <h2>User is not found</h2>;
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h2'>Edit user roles</Header>
                    {
                        loadingInitial && userForEdit === null
                            ?
                            <Loader active={loadingInitial} content={'Loading...'} />
                            :
                            <FinalForm
                                onSubmit={(user: IUserUpdateDtoModel) =>
                                    editUser(user)
                                        .then(() => history.push(Routes.Users()))
                                        .catch(error => ({ [FORM_ERROR]: error }))
                                }
                                initialValues={userForEdit!}
                                render={({ handleSubmit, submitting, submitError, dirtySinceLastSubmit }) => {

                                    return (
                                        <Form onSubmit={handleSubmit} loading={submitting} error>
                                            {submitError && !dirtySinceLastSubmit && (
                                                <ErrorMessage error={submitError} />
                                            )}
                                            <Field
                                                name='id'
                                                component={TextInput}
                                                hidden={true}
                                                value={userForEdit!.id} />
                                            <Form.Group>
                                                <Field
                                                    name='isAdminRole'
                                                    component={CheckboxInput}
                                                    placeholder='Administrator'
                                                    value={userForEdit!.isAdminRole} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Field
                                                    name='isUserRole'
                                                    component={CheckboxInput}
                                                    placeholder='User'
                                                    value={userForEdit!.isUserRole} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Field
                                                    name='isRegisteredRole'
                                                    component={CheckboxInput}
                                                    placeholder='Registered'
                                                    value={userForEdit!.isRegisteredRole} />
                                            </Form.Group>

                                            <Button
                                                floated='left'
                                                primary
                                                type='submit'
                                                content='Save' />
                                            <Button
                                                type='button'
                                                onClick={() => history.push(Routes.Users())}
                                                floated='left'
                                                content='Cancel' />
                                        </Form>)
                                }} />
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default observer(UserEditForm)
