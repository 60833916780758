import { observable, computed, action, runInAction } from 'mobx'
import { RootStore } from '../../app/stores/rootStore'
import { history } from '../..'

import { UserRow, IRegisterUserModel, IForgotPasswordModel, IResetPasswordModel, IUsersPageRequestModel, IUserUpdateDtoModel, IConfirmUserModel, Roles } from './models'
import agent from '../../app/api/agent'
import { JwtModel } from '../../app/models/jwt-model'
import PagingStoreBase from '../../app/stores/pagingStoreBase'
import { toast } from 'react-toastify'
import Routes from '../../app/layout/Routes'
const jwtDecode = require('jwt-decode')

export default class UserStore extends PagingStoreBase<IUsersPageRequestModel>
{
	constructor(protected rootStore: RootStore) {
		super(rootStore)
	}

	@observable users: UserRow[] = []
	@observable loadingInitial = false
	@observable confirmingUser = false
	@observable user: UserRow | null = null
	@observable userForEdit: IUserUpdateDtoModel | null = null

	@action loadPage = async () => {
		this.loadingInitial = true
		try {
			const result = await agent.User.list(this.requestModel).toPromise()
			runInAction('load users', () => {
				this.users = result.items
				this.totalCount = result.totalCount
				this.loadingInitial = false
			})
		} catch (error) {
			runInAction('load users error', () => {
				this.loadingInitial = false
			})
		}
	}

	@computed get isLoggedIn() {
		return !!this.user
	}

	@action login = async (values: IRegisterUserModel) => {
		try {
			const user = await agent.User.login(values).toPromise()

			if (user === null) {
				throw new Error('Network error. Server is unavailable')
			}

			runInAction(() => {
				this.user = user
			})
			this.rootStore.commonStore.setToken(user.token)
			this.rootStore.confirmModalStore.closeModal()
			let role = this.getRole(user.token)
			history.push(role === Roles.USER || role === Roles.ADMIN
				? Routes.Landing()
				: Routes.Profile())
		} catch (error) {
			throw error
		}
	}

	@action register = async (values: IRegisterUserModel) => {
		try {
			const user = await agent.User.register(values).toPromise()
			this.rootStore.commonStore.setToken(user.token)
			this.rootStore.confirmModalStore.closeModal()
			history.push('/registered')
		} catch (error) {
			throw error
		}
	}

	@action forgotPassword = async (values: IForgotPasswordModel) => {
		try {
			await agent.User.forgotPassword(values).toPromise()
			history.push(Routes.Login())
		} catch (error) {
			throw error
		}
	}

	@action confirmUser = async (values: IConfirmUserModel) => {
		this.confirmingUser = true
		try {
			await agent.User.confirm(values).toPromise()
			runInAction('confirming user', () => {
				this.confirmingUser = false
			})
		} catch (error) {
			runInAction('confirming user error', () => {
				this.confirmingUser = false
			})
			throw error
		}
	}

	@action resetPassword = async (values: IResetPasswordModel) => {
		try {
			await agent.User.resetPassword(values).toPromise()
			history.push(Routes.Login())
		} catch (error) {
			throw error
		}
	}

	@action getUser = async () => {
		try {
			const user = await agent.User.current().toPromise()
			runInAction(() => {
				this.user = user
			})
		} catch (error) {
			console.log(error)
		}
	}

	@action logout = () => {
		this.rootStore.commonStore.setToken(null)
		this.user = null
		history.push(Routes.Login())
	}

	getRole = (token: string) => (jwtDecode(token) as JwtModel).role

	@computed get isAdmin() {

		if (!this.rootStore.commonStore.token) {
			return false
		}

		return this.getRole(this.rootStore.commonStore.token) === Roles.ADMIN
	}

	@computed get isUser() {

		if (!this.rootStore.commonStore.token) {
			return false
		}

		return this.getRole(this.rootStore.commonStore.token) === Roles.USER
	}

	@computed get isRegistered() {

		if (!this.rootStore.commonStore.token) {
			return false
		}

		return this.getRole(this.rootStore.commonStore.token) === Roles.REGISTERED
	}

	@action loadUserDetails = async (id: string) => {

		this.loadingInitial = true
		try {
			const user = await agent.User.get(id).toPromise()
			runInAction('load user for edit', () => {
				this.userForEdit = user
				this.userForEdit.isAdminRole = user.roles.includes(Roles.ADMIN)
				this.userForEdit.isUserRole = user.roles.includes(Roles.USER)
				this.userForEdit.isRegisteredRole = user.roles.includes(Roles.REGISTERED)
				this.loadingInitial = false
			})
		}
		catch {
			runInAction('load user for edit error', () => {
				this.loadingInitial = false
				toast.error('Problem with loading user')
			})
		}
	}

	@action editUser = async (user: IUserUpdateDtoModel) => {
		try {
			const newRoles: string[] = []
			user.isRegisteredRole && newRoles.push(Roles.REGISTERED)
			user.isUserRole && newRoles.push(Roles.USER)
			user.isAdminRole && newRoles.push(Roles.ADMIN)
			const updateUserModel: IUserUpdateDtoModel = {
				id: user.id,
				roles: newRoles
			}

			await agent.User.update(updateUserModel).toPromise()
		}
		catch (error) {
			console.log(error)
			throw error
		}
	}
}
