import React, { useContext } from 'react'
import { Grid, Header, List } from 'semantic-ui-react'
import { RootStoreContext } from '../../app/stores/rootStore';
import { observer } from 'mobx-react-lite';
import './profile.scss'

const UserProfile: React.FC = () => {

    const rootStore = useContext(RootStoreContext);
    const { user } = rootStore.userStore;

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header as='h2'>Profile</Header>
                    <List className='profile-info'>
                        <List.Item>
                            <List.Header>
                                <Header as='h3'>Email:</Header>
                            </List.Header>
                            <List.Content>
                                {user!.email}
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Header>
                                <Header as='h3'>User name:</Header>
                            </List.Header>
                            <List.Content>
                                {user!.displayName}
                            </List.Content>
                        </List.Item>
                    </List>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

export default observer(UserProfile)
