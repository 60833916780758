export interface WidgetMagazineRow {
    id: string
    slug: string
    name: string
}

export enum PdfFileType {
    Normal = 1,
    Attachment = 2,
    Chronicles = 3,
}
